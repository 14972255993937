<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the garages Lymers have built for our customers
    </h2>
    <div class="services-grid garage-grid">
      <article class="services-grid-article garage-construction-image one">
      </article>

      <article class="services-grid-article garage-construction-image two">
      </article>

      <article class="services-grid-article garage-construction-image three">
      </article>
      
      <article class="services-grid-article garage-construction-image four">
      </article>

      <article class="services-grid-article garage-construction-image five">
      </article>

      <article class="services-grid-article garage-construction-image six">
      </article>

      <article class="services-grid-article garage-construction-image seven">
      </article>
      
      <article class="services-grid-article garage-construction-image eight">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.garage-construction-image {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/garages/construction/3.jpg");
  }
  &.two {
    background-image: url("@/img/services/garages/construction/4.jpg");
  }
  &.three {
    background-image: url("@/img/services/garages/construction/5.jpg");
  }
  &.four {
    background-image: url("@/img/services/garages/construction/6.jpg");
  }
  &.five {
    background-image: url("@/img/services/garages/construction/7.jpg");
  }
  &.six {
    background-image: url("@/img/services/garages/construction/8.jpg");
  }
  &.seven {
    background-image: url("@/img/services/garages/construction/9.jpg");
  }
  &.eight {
    background-image: url("@/img/services/garages/construction/10.jpg");
  }
}
</style>