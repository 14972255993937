<template>
<!-- Header -->
  <header
    class="nav nav--always-fixed fixed-headline"
    id="reduced"
    itemtype="http://schema.org/WPHeader"
    itemscope
  >
    <div class="nav__holder">
      <div class="container-fluid container-semi-fluid">
        <div class="flex-parent">
          <div class="nav__header clearfix">
            <!-- Logo -->
            <div class="logo-wrap local-scroll">
              <router-link to="/" class="nav-link">
                <img
                  class="nav-logo"
                  src="@/img/branding/header-logo.svg"
                  srcset="@/img/branding/header-logo.svg"
                  alt="lymers logo"
                  itemtype="http://schema.org/Organization"
                  itemscope
                />
              </router-link>
            </div>

            <button
              type="button"
              class="nav__icon-toggle"
              id="nav__icon-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
            </button>
          </div>
          <!-- end nav-header -->

          <!-- Navbar -->
          <nav
            id="navbar-collapse"
            class="nav__wrap collapse navbar-collapse nav-align-middle"
            itemtype="http://schema.org/SiteNavigationElement"
            itemscope="itemscope"
          >

            <ul class="nav__menu local-scroll nav-drop-down" id="onepage-nav">
              <!-- Home -->
              <li>
                <router-link to="/" class="nav-link">Home</router-link> 
              </li>

              <!-- Garage Doors -->
              <li class="nav__dropdown">
                <router-link to="Garages" class="nav-link"
                aria-expanded="false"
                aria-haspopup="true"
                role="button"
                >Garage Doors & Garages
                </router-link>
                <i
                  v-bind:class="(activeClass)"
                  class="nav__dropdown-trigger"
                  role="button"
                  @click="toggledroparrow()"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <ul class="nav__dropdown-menu">
                  <li class="option" style="list-style: none; float: none text-align: left !important;">
                    <router-link to="Garages-Up-And-Over">
                      Up-And-Over
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Garage-Side-Hinged-Doors">
                      Side-Hinged-Doors
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Garage-Sectional-Doors">
                      Sectional-Doors
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Garage-Roller-Doors">
                      Roller-Doors
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Garage-Builds">
                      Garage-Construction
                    </router-link>
                  </li>                  
                </ul>
              </li>

              <!-- Windows and Doors -->
              <li class="nav__dropdown">
                <router-link to="Windows" class="nav-link"
                aria-expanded="false"
                aria-haspopup="true"
                role="button"
                >Windows & Doors
                </router-link>
                <i
                  v-bind:class="(activeClass)"
                  class="nav__dropdown-trigger"
                  role="button"
                  @click="toggledroparrow()"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <ul class="nav__dropdown-menu">
                  <li class="option">
                    <router-link to="Windows">
                      Windows
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Domestic-Doors">
                      Doors
                    </router-link>
                  </li>
                </ul>
              </li>

              <!-- Gates -->
              <li>
                <router-link to="Gates" class="nav-link">Gates & Railings</router-link> 
              </li>

              <!-- Veranda Conservatory & Carports -->
              <li>
                <router-link to="Veranda-Conservatory-Carports" class="nav-link">Conservatories, Verandas and Carports</router-link> 
              </li>

              <!-- Industrial & Electrical -->
              <li class="nav__dropdown">
                <router-link to="Industrial" class="nav-link"
                aria-expanded="false"
                aria-haspopup="true"
                role="button"
                >Industrial & Electrical
                </router-link>
                <i
                  v-bind:class="(activeClass)"
                  class="nav__dropdown-trigger"
                  role="button"
                  @click="toggledroparrow()"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></i>
                <ul class="nav__dropdown-menu">
                  <li class="option" style="list-style: none; float: none text-align: left !important;">
                    <router-link to="Industrial">
                      Industrial
                    </router-link>
                  </li>
                  <li class="option">
                    <router-link to="Electrical">
                      Electrical
                    </router-link>
                  </li>                  
                </ul>
              </li>

              <!-- News -->
              <li>
                <router-link to="News" class="nav-link">News</router-link> 
              </li>

              <!-- Contact us -->
              <li>
                <router-link to="Contact" class="nav-link">Contact</router-link>
              </li> 

              
            </ul>

          </nav>

          <!-- end nav-wrap -->
        </div>
        <!-- end flex-parent -->
      </div>
      <!-- end container -->
    </div>
  </header>
  <!-- end navigation -->

</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return{
         activeClass: 'ui-arrow-down'
    }
  },
  methods:{
      toggledroparrow(){
        
        if(this.activeClass == 'ui-arrow-down')
        {
          this.activeClass = 'ui-arrow-up'
        }
        else
        {
          this.activeClass = 'ui-arrow-down'
        }
      }
  },
  mounted: function(){

  }
};
</script>

<style lang="scss">

</style>