<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers provides a first class service to all of our customers.
        </h2>
        <p>
          Here at Lymers not only do we take care of Garages, Windows, Doors and Gates but we also provide electrical services to our customers across Staffordshire and Cheshire.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>