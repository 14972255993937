<template>
  <div class="news-page">
    <div class="news-page-intro-container">
      <div class="news-page-text-container">
        <h2>Aluminium Windows</h2>
        <h4 class="news-page-subtext">Aluminium provides a clean and stylish design and suits any home owner that is looking for high quality and contemporary living with stylish aesthetics and incredible durability.
        </h4>
        <br/><br/>
        <ul class="news-page-subtext text-left">
          <li>
            All REAL Aluminium profiles utilise innovative polyamide thermal break technology which creates a barrier between the cold air outside and the warm air inside.
          </li>
          <li>
            This technology significantly reduces thermal transmittance and enhances the overall U Value of a product.
          </li>
        </ul>
      </div>      
    </div>

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container aluminium-windows-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner aluminium-windows-type-grid">                
                  <img src="@/img/services/windows/aluminiumgrid/contemporary-flat.jpg" alt="">
                  <div class="tile-text aluminium-windows-type-grid">
                    <h2 class="article-info-headline">Contemporary frame - Flat sash</h2>
                    <h4>
                       Internally glazed, square bead with dummy sashes
                    </h4>
                    <p class="article-info-body-text aluminium-windows-type-grid">
                      The square-edged profile of the ‘Contemporary’ window provides clean, slim sightlines and a modern finish to any home.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner aluminium-windows-type-grid">
                  <img src="@/img/services/windows/aluminiumgrid/contemporary-bevel.jpg" alt="">
                  <div class="tile-text aluminium-windows-type-grid">
                    <h2 class="article-info-headline">Contemporary frame - Bevel sash</h2>
                    <h4>
                       Externally glazed, square bead
                    </h4>
                    <p class="article-info-body-text aluminium-windows-type-grid">
                      The square-edged profile of the ‘Contemporary’ window provides clean, slim sightlines and a modern finish to any home.
                    </p>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';


</style>


