<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/contact/contact-hero.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>
          <div class="blogpage">
            <div class="blogpage-intro-container">
              <div class="blogpage-text-container">
                <h2>Hormann Doors</h2>
                <h4 class="blogpage-subtext">Lymers are a leading supplier for <em>Hormann</em> and are very proud to offer a high-quality Hörmann front door, either as a stand alone door or to match a new or existing Hormann garage door. This transforms any property and creates a striking view when approaching your home.
                </h4>
              </div>      
            </div>

            <div class="">      
              <!-- Project Navigation Tile -->
              <section class="image-text floors">
                <Grid class="pt2" center middle>
                  <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                    <div class="tile-container hormann-doors-type-grid">

                      <!-- New tile article goes here -->
                      <div class="tile">
                        <div class="tile-inner hormann-doors-type-grid">                
                          <img src="@/img/services/doors/hormann-type-grid/hormann-thermo65.jpg" alt="">
                          <div class="tile-text hormann-doors-type-grid">
                            <h2 class="article-info-headline">Thermo 65</h2>
                            <p class="article-info-body-text hormann-doors-type-grid">
                              <ul>
                                <li>
                                  The solid 65-mm-thick steel door leaf with PU rigid foam infill and the leaf profile on the inside made of composite material keep the cold out. With a UD value of up to 0.87 W/(m²·K) Thermo65 doors offer excellent thermal insulation.
                                </li>
                                <li>
                                  As standard, the doors are equipped with a 5-point security lock, allowing you to feel safe and secure in your home. Thermo65 styles are optionally available with RC 2 safety equipment to make you feel even more secure in your own four walls.
                                </li>
                              </ul>
                            </p>
                          </div> 
                        </div>
                      </div>

                      <div class="tile">
                        <div class="tile-inner hormann-doors-type-grid">
                          <img src="@/img/services/doors/hormann-type-grid/hormann-thermo46.jpg" alt="">
                          <div class="tile-text hormann-doors-type-grid">
                            <h2 class="article-info-headline">Thermo 46</h2>
                            <p class="article-info-body-text hormann-doors-type-grid">
                              The Thermo46 doors offer you everything that makes for an excellent front door.
                              <ul>
                                <li>
                                  With a UD-value up to approx. 1.1 W/(m²·K), the Thermo46 door achieves excellent thermal insulation values.
                                </li>
                                <li>
                                  Thanks to the standard 5-point security lock, the front doors are also ideally equipped when it comes to security. Some styles are optionally available with RC 2 safety equipment to make you feel even more secure in your own four walls.
                                </li>
                                <li>
                                  Choose your favourite from 13 door styles – from modern to classic. You can customise your Hörmann door with colours, glazings,side elements and transom lights according to your preferences.
                                </li>
                              </ul>
                            </p>
                          </div> 
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </Grid>
              </section>
            </div>

            <!-- Security Table -->
            <div class="blogpage-intro-container">
              <div class="blogpage-text-container">
                <h2>Security</h2>
                <h4 class="blogpage-subtext">
                  For you and your family, being able to rest easy in your home is important. This is why our Thermo65/Thermo46 are equipped with multi-point locking as standard. For even more security these doors are available with break-in-resistant RC-2 security equipment.
                </h4>

                <table class="client-table">
                  <tr class="client-table-header">
                    <th>
                      Categories / product type
                    </th>
                    <th>
                      Thermo65
                    </th>
                    <th>
                      Thermo46
                    </th>
                  </tr>
                  <tr>
                    <tb>
                      Door leaf
                    </tb>
                    <tb>
                      65-mm-thick steel door leaf
                    </tb>
                    <tb>
                      46-mm-thick steel door leaf
                    </tb>
                  </tr>
                  <tr>
                    <tb>
                      Thermal insulation
                    </tb>
                    <tb>
                      UD value up to 0.87W/(m²·K)*
                    </tb>
                    <tb>
                      UD value up to 1.1W/(m²·K)*
                    </tb>
                  </tr>
                  <tr>
                    <tb>
                      Available safety equipment
                    </tb>
                    <tb>
                      RC 2
                    </tb>
                    <tb>
                      RC 2 available for door styles TPS 700 / 750 / 900 / 010 / 015 and 100 (without side elements and transom lights)
                    </tb>
                  </tr>
                  <tr>
                    <tb>
                      Frame
                    </tb>
                    <tb>
                      80 mm aluminium frame with thermal break
                    </tb>
                    <tb>
                      60 mm aluminium frame with thermal break
                    </tb>
                  </tr>
                </table>
              </div>      
            </div>
          </div>
      </div>
    </main>
  </body>
</template>

<script>
    export default {
    name: "News",
    components: {
      }
    };

</script>

<style lang="scss">

</style>
