<template>
  <div class="news-page">

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container verandacon-type-grid">

              <!-- New tile article goes here -->
              
              <div class="tile">
                <div class="tile-inner verandacon-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/icotherm-grid-image.jpg" alt="">
                  <div class="tile-text verandacon-type-grid">
                    <h2 class="article-info-headline">Icotherm - Solid roof system</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Icotherm-Roof" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner verandacon-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/glass-roof.jpg" alt="">
                  <div class="tile-text verandacon-type-grid">
                    <h2 class="article-info-headline">UPVC - Glass roof</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-UPVC-Conservatory-Roof" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>  

              <div class="tile">
                <div class="tile-inner verandacon-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/verandagrid.jpg" alt="">
                  <div class="tile-text verandacon-type-grid">
                    <h2 class="article-info-headline">Verandas, Canopies & Carports</h2>
                    <a href="https://www.molan-uk.com/library/Clearview_Brochure_-_Unbranded_(Compressed).pdf" target="_blank" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container lymer-service-button">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>View Catalogue</span>
                          </button>
                        </div>
                      </a>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


