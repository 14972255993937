<template>
  <div class="latest-news-container">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container news">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do sidenews">
            <div class="center">
              <div class="side-by-side">                    
                <div class="latest-news-grid side sm-90 d50 l40">
                  <img class="news-image" src="@/img/home/latest-news.jpg">
                </div>
                <div class="side news-text-pad sm-90 d50 l60">
                  <h2 class="latest-news-headline">Latest News</h2>
                  <h4 class="latest-news-sub-headline">Lymers partnered with Comp Door</h4>
                  <ul class="what-we-do-body-text sidenews">
                    <p class="latest-news-body-text">
                      We are proud to be partnered with Comp Door for our range of composite doors. A local company who manufacture a high quality door in a great range of traditional and contemporary styles that are available in a wide choice of colours.
                    </p>
                                       
                  </ul>
                  <div class="services-grid-article-button-container ">
                      <router-link to="/Blog-Comp-Door" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container latest-news-button">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Click here</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

  
</style>