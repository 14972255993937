<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers provides a first class service to all of our customers.
        </h2>
        <p>
          Lymers can supply and install a range of gate and railing options, offering security with a stylish look.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>