<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Designed for versatility and ease of use</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Insulated Roller Doors –  foam filled roller garage doors are manufactured to the highest standards, using only the best materials available, we can supply and install automatic roller garage doors, with a range of options to suit everybody’s budget.
                      
                      <br/><br/>
                      Speak to a member of our sales team today to arrange a quote for your next roller garage door.                     
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid garage-roller-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

  <div class="">      
    <!-- Roller doors -->
    <section class="image-text floors">
      <Grid class="pt2" center middle>
        <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
          <div class="tile-container roller-doors-type-grid">

            <!-- New tile article goes here -->
            <div class="tile">
              <div class="tile-inner roller-doors-type-grid">                
                <img src="@/img/services/garages/roller/insulated-roller.jpg" alt="">
                <div class="tile-text roller-doors-type-grid">
                  <h2 class="article-info-headline">Insulated Roller Doors</h2>
                  <p class="article-info-body-text roller-doors-type-grid">
                    <ul>
                      <li>
                        Opening vertically allows you to park as close as you like on your drive and inside the garage, no out-swing or curving around overhead tracks. The 77mm aluminium foam filled lath is designed to significantly reduce the chill factor, draught brushes are inside the guide frame which also help the door run smoothly and quietly, rubber threshold seal and a draught brush at the top.
                      </li>
                      <li>
                        The housing box into which the door rolls is typically just 300mm deep, from the inside is neat, uncluttered and easy to keep clean. Conveniently opens with the press of a button, automated via a 240volt tubular motor, built in safety edge instantly stops and reverses the door a short distance when an obstruction compresses the seal.  These doors are available in over 40 different colours and wood grain effect finishes.
                      </li>
                      <li>
                        The strength of the slat has been vigorously tested and spans openings of up to 5000mm wide.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>

            <div class="tile">
              <div class="tile-inner roller-doors-type-grid">
                <img src="@/img/services/garages/roller/compact.jpg" alt="">
                <div class="tile-text roller-doors-type-grid">
                  <h2 class="article-info-headline">Compact Roller Doors</h2>
                  <p class="article-info-body-text roller-doors-type-grid">
                    <ul>
                      <li>
                        The compact door is made up of 55mm aluminium lath, rather than the traditonal 77mm which makes the coil even smaller and tighter, allowing the headroom to start from as little as 205 mm.
                      </li>
                      <li>
                        The Compact uses a narrower guide rail profile than the standard, at just 60mm, they can be recessed into a smaller space and if the door needs to be fitted into the reveal of the opening, the reduced guide size gives an additional 30mm of drive-through width. The Compact roller door is available in a number of colour options, all produced from the same corrosion resistant insulated aluminium as used on the standard. The versatility of this door means that it is the perfect solution for garages up to 3000 mm in width.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>
            
          </div>
        </div>
      </Grid>
    </section>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';


</style>