<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/contact/contact-hero.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>
        <NewsGrid/>
      </div>
    </main>
  </body>
</template>

<script>
import NewsGrid from "@/components/NewsGrid.vue";
    export default {
    name: "News",
    components: {
        NewsGrid
    }
    };

</script>

<style lang="scss">

</style>
