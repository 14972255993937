<template>
        
  <!-- Services Boxes -->
  <div id="services" class="contact-services home">
    <h3 class="text-center">
      All of our engineers are safety trained & qualified. Lymers is a registered and authorised installer.
    </h3>
    <div class="contact-grid">
      <article class="contact-article">
        <img src="@/img/contact/1.png" alt=""/>
      </article>

      <article class="contact-article">
        <img src="@/img/contact/2.png" alt=""/>
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';
  .secondary-back {
    background-color: $primary-colour;
  }

  .contact-grid {
    display: grid;
    width: -webkit-fill-available;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    padding: 0.5rem 0.5rem;
    margin: 0em 0em;
    grid-gap: 1em;
  }

  .contact-article {
    border-radius: 0px;
    background-size: cover;
    background-position: center;
    background-color: $secondary-color;
    margin-bottom: 0.5em;
    border-radius: 10px;
    max-height: 10rem;
    min-height: 10rem;
    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    overflow: hidden;

    @include breakpoint-small-mobile {
      max-height: 5rem;
      min-height: 5rem;
    }

    @include breakpoint-mobile {
      max-height: 5rem;
      min-height: 5rem;
    }
  }

  .contact-services{
    padding: 0.5rem 0rem;
    margin: 1rem 0rem 0rem;

    h1,h2,h3,h4,h5,h6 {
      width: 80%;
      margin: 2rem auto;
      color: $primary-colour;
    }
  }
</style>