<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers - Windows
        </h2>
        <p>
          Whether you’re looking to replace old and drafty windows or you want to give your home a whole new make over, Lymers can help you make the right choice. We offer a wide range of UPVC and aluminium window styles that are available in a choice of colours.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>