<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-splash blog-real-aluminium-door"></div>
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Don’t have a doorway into your garden?<br/>No problem, we’ll make one….</h2>
              <h4 class="blogpage-subtext">
              </h4>
            </div>      
          </div>

        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side new-door">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            Most homes have a window overlooking the garden and for many of us this would make a great way to enter the garden.
                            <br/><br/>
                            Just imagine those warm summer months with the doors wide open creating that connection between the inside and outside we all read about in magazines.
                            <br/><br/>
                            This might be easier to achieve than you think. If you have an existing window we simply remove the brickwork below, install the new door and make good inside. Just leaving you to decorate your new room.
                            <br/><br/>                         
                            This was a single door with a ‘flag’ window next to it. The old frames were removed as were the section of brick and plaster.
                            <br/><br/>
                            A new sliding Patio Door was installed and the inside was plastered ready for decorating. This has created an opening doorway that is a bit wider and lets more light into the main house. More importantly, with the sliding door the single door does not open into the conservatory, this creates more space and a feeling of openness when the door is open.
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid new-door-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <div class="blogpage-intro-container">
          <div class="blogpage-text-container">
            <h4 class="blogpage-subtext">
              This was a single window on the cottage which has had the brickwork below removed creating a new doorway, French Door installed with threshold board, inside reveals plastered all ready for decorating. Cottages often have small windows and can be darker than we would like. Creating an opening allows more light in due to the extra glass and opens up views across the countryside.
            </h4>

            <div class="tile-container double-image-grid new-door">
              <div class="tile">
                <div class="tile-inner double-image-grid new-door">                
                  <img src="@/img/news/11-01-24-blog/new-door2.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner double-image-grid new-door">                
                  <img src="@/img/news/11-01-24-blog/new-door3.jpg" alt="">
                </div>
              </div>
            </div>
          </div>      
        </div>

      </div>
    </main>
  </body>
</template>

<script>
    export default {
    name: "News",
    components: {
      }
    };

</script>

<style lang="scss">
  
</style>
