<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-splash upvc-conservatory-roof-bg"></div>
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>UPVC Conservatory Roof</h2>
              <h4 class="blogpage-subtext">
                Lymers UPVC glass roofs offer a classic look for your conservatory. The glass panels open up the sky line and let in an abundance of light. Creating a room to sit and enjoy views of your garden and beyond, entertain family and friends and they are also great for sitting in during the evening and watching the night-time sky go by.
              </h4>
            </div>      
          </div>
        </div>

        <!-- Why Lymers choose Rehau windows -->
        <div class="lymer-services-container compdoor">          
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side upvc-roof">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <ul class="what-we-do-body-text lymer-service">                          
                          <p class="lymer-service-body-text">
                            Lymers UPVC glass roofs offer a classic look for your conservatory. The glass panels open up the sky line and let in an abundance of light. Creating a room to sit and enjoy views of your garden and beyond, entertain family and friends and they are also great for sitting in during the evening and watching the night-time sky go by.
                            <br/><br/>
                            UPVC roofs are available in an extensive range of customisable options and a wide range of colours to best match  the aesthetic you are looking for. Optional extras include an internal pelmet with lighting and a roof vent for increased air circulation within the conservatory.
                            <br/><br/>
                            Glass conservatory roofs are fabricated with longevity in mind. The roofs are designed to withstand even the most extreme weather conditions.                    
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <router-link to="Contact" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>Contact Us</span>
                              </button>
                            </div>
                          </router-link>
                        </div>                          
                      </div>
                      <div class="lymer-services-grid upvc-conservatory-roof-side-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <div class="">                
          <!-- Project Navigation Tile -->
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Glass Colour Options</h2>           
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container blog-type-grid four colour-options">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-01-24-blog/neutral.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Neutral
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-01-24-blog/aqua.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Aqua
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-01-24-blog/blue.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Blue
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-01-24-blog/bronze.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Bronze
                        </p>
                      </div> 
                    </div>
                  </div>
                 
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <WindowsParallax/>

      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
    export default {
    name: "News",
    components: {
      WindowsParallax,
      }
    };

</script>

<style lang="scss">
  
</style>
