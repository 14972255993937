<template>
  <div class="news-page">

    <div class="">      
      <!-- Project Navigation Tile -->
      <div class="image-text floors">
        <div class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container veranda-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner veranda-type-grid">                
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-black.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Black</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-brown.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Brown</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-brindle-red.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Brindle Red</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-red.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Red</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-brindle-brown.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Brindle Brown</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icotile-shingle-grey.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoTile (Shingle) - Grey</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icoslate-slate-amethyst.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoslate (Slate Effect) - Amethyst</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icoslate-slate-ash.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoslate (Slate Effect) - Ash</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icoslate-slate-oak.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoslate (Slate Effect) - Oak</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icoslate-slate-sunset.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoslate (Slate Effect) - Sunset</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner veranda-type-grid">
                  <img src="@/img/services/veranda-conservatory-carports/typegrid/icoslate-slate.jpg" alt="">
                  <div class="tile-text veranda-type-grid">
                    <h2 class="article-info-headline">icoslate (Slate Effect) - Slate</h2>
                    <p class="article-info-body-text veranda-type-grid">
                    </p>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


