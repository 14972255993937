<template>
  <!-- Could make this nav a component later -->
<div id="app">
  <Headline />
  <Navigation/>
  <router-view/>
  <Footer />
  <modal v-show="isModalVisible" @close="closeModal" />
</div>
</template>

<script>
import Headline from "@/components/Headline.vue";
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'
import Modal from './components/ContactModal.vue'

export default {
  name: 'Lymers-B2C',
  components: {
    Headline,
    Navigation,
    Footer,
    Modal
  },
   data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
}

</script>

<style lang="scss">

@import "@/styles/style.scss";

</style>
