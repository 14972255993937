<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the garages Lymers have designed and fitted for our customers
    </h2>
    <div class="services-grid garage-grid">
      <article class="services-grid-article garage-side-hinged-image one">
      </article>

      <article class="services-grid-article garage-side-hinged-image two">
      </article>

      <article class="services-grid-article garage-side-hinged-image three">
      </article>
      
      <article class="services-grid-article garage-side-hinged-image four">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.garage-side-hinged-image {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/garages/side-hinged/1.JPG");
  }
  &.two {
    background-image: url("@/img/services/garages/side-hinged/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/garages/side-hinged/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/garages/side-hinged/4.jpg");
  }
}
</style>