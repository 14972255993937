<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="lymer-services-grid access-control-services-bg side sm-90 d50 l40">
                </div>
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Access control</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      There are different options available to make it more convenient for access to your gates. Gate access control is managing access in and out of a gated property. It ensures that authorised visitors can enter your property while restricting access to unauthorised people. Controlling access at your gate keeps your property secure. You can have a simple push button that will notify a handset inside the property or have a full video system which allows you to communicate with visitors as well as visually see them. There are a lot of different options to suit everyone.                                     
                    </p>              
                  </ul>
                  
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
  
</style>