<template>
  <div class="lymer-services-container garages">

    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h3 class="lymer-service-headline service-headline">Extensive range of Conservatories, Verandas and Carports</h3>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      All of our Conservatories, Verandas and Carports are manufactured using the highest quality materials and are all designed to exact specifications. Here at Lymers we can work with you to provide the exact results so you can enjoy your outdoor space.                      
                      <br/><br/>
                      Speak to a member of our sales team today to arrange a quote for your next Veranda, Conservatory or Carport.                     
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid veranda-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>    
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

</style>