<template>
  <div class="feedback-container garage-manufacturers feedback-two">
    <h2>
      Our Suppliers
    </h2>
    <Carousel class="carousel-large" :autoplay="3000" :items-to-show="5" :wrap-around="true" :transition="500">
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/AlluGuard.png" alt="AlluGuard Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/BFT.png" alt="BFT Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Gliderol.png" alt="Gliderol Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Hormann.png" alt="Hormann Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Nice.png" alt="Nice Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/NovoFern.png" alt="NovoFern Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Priory.png" alt="Priory Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Ryterna.png" alt="Ryterna Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/SOMMER.png" alt="SOMMER Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Teckentrup.png" alt="Teckentrup Logo">
        </div>
      </Slide>
      <!-- <template #addons>
        <Pagination />
      </template> -->
    </Carousel>

    <Carousel class="carousel-small" :autoplay="3000" :items-to-show="3" :wrap-around="true" :transition="500">
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/AlluGuard.png" alt="AlluGuard Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/BFT.png" alt="BFT Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Gliderol.png" alt="Gliderol Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Hormann.png" alt="Hormann Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Nice.png" alt="Nice Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/NovoFern.png" alt="Novo Fern Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Priory.png" alt="Priory Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Ryterna.png" alt="Ryterna Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/SOMMER.png" alt="SOMMER Logo">
        </div>
      </Slide>
      <Slide :key="slide">
        <div class="carousel__item">
          <img src="@/img/services/garages/manufacturers/Teckentrup.png" alt="Teckentrup Logo">
        </div>
      </Slide>
      <!-- <template #addons>
        <Pagination />
      </template> -->
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'

export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
})
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}
:root {
  /* Color */
  --vc-clr-primary: #000;
  --vc-clr-secondary: #090f207f;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-height: 30px;
  --vc-nav-border-radius: 0;
  --vc-nav-color: var(--vc-clr-primary);
  --vc-nav-color-hover: var(--vc-clr-secondary);
  --vc-nav-background: transparent;

  /* Pagination */
  --vc-pgn-width: 12px;
  --vc-pgn-height: 4px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  width: 80vw;
  padding: 0rem;
  margin-left: auto;
  margin-right: auto;
}

.carousel-small {
  @include breakpoint-small-mobile {
    display: block;
  }

  @include breakpoint-mobile{
    display: block;
  }

  @include breakpoint-tablet {
    display: none;
  }

  @include breakpoint-large-tablet{
    display: none;
  }

  @include breakpoint-small-laptop {
    display: none;
  }

  @include breakpoint-laptop {
    display: none;
  }

  @include breakpoint-large-laptop {
    display: none;
  }

  @include breakpoint-desktop {
    display: none;
  }

  @include breakpoint-extra-large {
    display: none;
  }
}

.carousel-large {
  @include breakpoint-small-mobile {
    display: none;
  }

  @include breakpoint-mobile{
    display: none;
  }

  @include breakpoint-tablet {
    display: block;
  }

  @include breakpoint-large-tablet{
    display: block;
  }

  @include breakpoint-small-laptop {
    display: block;
  }

  @include breakpoint-laptop {
    display: block;
  }

  @include breakpoint-large-laptop {
    display: block;
  }

  @include breakpoint-desktop {
    display: block;
  }

  @include breakpoint-extra-large {
    display: block;
  }
}

.carousel__item {
  margin: 1rem 0.5rem;

  img {
    width: 80%;
  }
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

.carousel__viewport {
  overflow: hidden;
}

.carousel__sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Fix iOS scrolling #22 */
  transform: translateZ(0);
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  line-height: 0;
  margin: 10px 0 0;
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: transparent;

  button:focus {
    background-color: none !important;
  }
}

.carousel__pagination-button::after {
  display: block;
  content: '';
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-border-radius);
  background-color: var(--vc-pgn-background-color);
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: var(--vc-pgn-active-color);
}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background: var(--vc-nav-background);
  border-radius: var(--vc-nav-border-radius);
  width: var(--vc-nav-width);
  height: var(--vc-nav-height);
  text-align: center;
  font-size: var(--vc-nav-height);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__prev:hover,
.carousel__next:hover {
  color: var(--vc-nav-color-hover);
}

.carousel__next--disabled,
.carousel__prev--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.carousel__prev {
  left: 0;
}

.carousel__next {
  right: 0;
}

.carousel--rtl .carousel__prev {
  left: auto;
  right: 0;
}

.carousel--rtl .carousel__next {
  right: auto;
  left: 0;
}

</style>