<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h4 class="text-center">
      See some of the doors Lymers have designed and fitted for our customers
    </h4>
    <div class="services-grid garage-grid">
      <article class="services-grid-article doors-art one">
      </article>

      <article class="services-grid-article doors-art two">
      </article>

      <article class="services-grid-article doors-art three">
      </article>
      
      <article class="services-grid-article doors-art four">
      </article>

      <article class="services-grid-article doors-art five">
      </article>
      
      <article class="services-grid-article doors-art six">
      </article>

      <article class="services-grid-article doors-art seven">
      </article>
      
      <article class="services-grid-article doors-art eight">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.doors-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/doors/1.jpg");
  }
  &.two {
    background-image: url("@/img/services/doors/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/doors/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/doors/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/doors/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/doors/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/doors/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/doors/8.jpg");
  }
}
</style>