<template>
  <div class="news-page">
    <div class="news-page-intro-container">
      <div class="news-page-text-container">
        <h2>See the latest news and information from all of the team here at Lymers.</h2>
        <h4 class="news-page-subtext">You will find everything from details regarding our latest sales, helpful tips to look after your garages, windows & doors, as well as news and information about all the goings on here at Lymers. Click on any of the individual articles to find out more information. 
        </h4>      
      </div>      
    </div>

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors news-page-grid">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container">              

              <!-- Icotherm Roof Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/icotherm1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Icotherm Roof</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        If you have a conservatory with a glass or polycarbonate roof you will already know that it is cold in the winter and, if it is in direct sunlight, too hot in the summer. This means you only get to use the space for around half the year.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Icotherm-Roof" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <!-- UPVC Conservatory Roof Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/UPVC-ConservatoryRoof.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">UPVC Conservatory Roof</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        Lymers UPVC glass roofs offer a classic look for your conservatory. The glass panels open up the sky line and let in an abundance of light. Creating a room to sit and enjoy views of your garden and beyond, entertain family and friends and they are also great for sitting in during the evening and watching the night-time sky go by.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-UPVC-Conservatory-Roof" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>
              
              <!-- UPVC French doors Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/tilt-turn1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Tilt and Turn</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        REHAU UPVC tilt & turn windows allow maximum opening widths with the option of a full or limited amount of ventilation. With the tilt & turn opening system, this stylish UPVC window can be tilted inwards from the top for normal ventilation or can be fully opened from the side for full ventilation and ease of maintenance. Inward opening configurations allow for an effective fire escape and ideal for areas with restricted external space for an outward opening solution.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Tilt-Turn" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <!-- UPVC French doors Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/patio1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Patio/Sliding Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        The beauty of sliding doors lies in their ability to provide virtually unhindered views of the great outdoors while being unobtrusive within your living space. With a choice of 2, 3 or 4 panel doors available, all our sliding doors come with superior security fittings. Even with these features, the easy-glide doors carry slim sightlines, allowing you to see less frame and more garden.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Patio-Sliding-Door" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <!-- UPVC French doors Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/new-door1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Making way for a new door</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        Most homes have a window overlooking the garden and for many of us this would make a great way to enter the garden.Just imagine those warm summer months with the doors wide open creating that connection between the inside and outside we all read about in magazines.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Making-Way-For-New-Door" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>
              
              <!-- UPVC French doors Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/french-door1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">UPVC French doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        A french door manufactured from REHAU profile is a charming addition to any home or conservatory. The extensive opening allows unrestricted access to your home as well as maximum ventilation and practical entry to your garden or patio.Offering all the benefits of modern uPVC including noise reduction, low maintenance, safety, security and energy efficiency.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-UPVC-French-Doors" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <!-- REAL Aluminium Doors Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-01-24-blog/real-1.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">REAL Aluminium Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        Lymers will always source products we feel will stand the test of time and the Real Aluminium Doors are no exception. Designed as a stand alone product or to complement our aluminium window ranges an aluminium door will add style, security and individuality to your home as well as showcasing uninterrupted views of your garden and beyond.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Real-Aluminium-Door" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>
              
              <!-- Comp Door Blog-->
              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/10-01-24-blog/compdoorblog.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Comp Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        When you buy a Comp Door composite entrance door, you’ll instantly benefit from years of expert engineering combined to create a premium door with market-leading performance at a competitive price.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Comp-Door" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-12-23-upvc.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">UPVC Residential Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        UPVC doors have been the popular choice for many years. Residential, french doors are available in a wide range of styles and finishes and can be manufactured to open inward or outward.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-UPVC-Doors" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/11-12-23.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Standard Casement</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        Casement windows are the most popular style of replacement window in the UK – an extremely versatile window that will suit all styles of home ensuring you can make a real statement.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Rehau-Windows" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/05-12-23.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Ryterna Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        The Ryterna RD80 is the perfect door choice if you have chosen a Ryterna garage door and would like your front to match. These doors offer high quality with some great design features.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Ryterna-Doors" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/services/doors/suppliers/hoorman-door.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Horman Doors</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        Lymers are a leading supplier for Horman and are very proud to offer a high-quality Horman front door, either as a stand alone door or to match a new or existing Horman garage door. This transforms any property and creates a striking view when approaching your home.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Hormann_Doors" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner">                
                  <img src="@/img/news/18-11-23.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Sliding gate</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        New install of bespoke aluminium sliding gate fully automated with Nice Robus 600 sliding gate motor, Nice wireless keypads and safety Photocells. Finished in Painswick Grey.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Sliding-Gate" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div>                   
                </div>
              </div>

              <div class="tile">
                <a class="tile-inner" href="https://lymerdoors.co.uk/" target="_blank">
                  <img src="@/img/news/18-11-23-a.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Flush Fit Windows</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        We have replaced the timber windows and doors with Rehau Flush Fit UPVC windows, new aluminium patio door and the front door has been replaced with a Comp Door.
                        <br/><br/>
                        Stone cills have been fitted below windows and maintenance free UPVC fascia, soffit and guttering has all been fitted throughout.
                      </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Flush-Fit-Windows" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </a>
              </div>

              <div class="tile">
                <a class="tile-inner" href="https://lymerdoors.co.uk/" target="_blank">
                  <img src="@/img/news/18-11-23-b.jpg" alt="">
                  <div class="tile-text">
                    <h3 class="news-page-headline">Front Door Combination Transformation</h3>
                    <h4 class="news-page-sub-headline"></h4>
                      <p class="news-page-body-text">
                        The old UPVC door was replaced with a composite door from Comp Door. This has completely changed the look of the door and improved the security and insulation properties, whilst keeping the inside white keeps the hallway light if the size of the glass is reduced.
                      </p>     
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Front-Door-Transformation" class="services-grid-article-button">
                        <!-- <button class="client-button">Read More</button> -->
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </a>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

  
</style>


