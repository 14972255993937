<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Style and quality that enhances the look of your property</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Steel garage doors offer a great combination of robust construction and excellent value for money. They are available in a wide range of attractable styles, colours, and gear options to suit modern or traditional homes. 
                      
                      <br/><br/>
                      Speak to a member of our sales team today to arrange a quote for your next up and over garage door.                     
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid garage-up-and-over-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

  <div class="">      
    <!-- Up and over doors -->
    <section class="image-text floors">
      <Grid class="pt2" center middle>
        <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
          <div class="tile-container up-and-over-doors-type-grid">

            <!-- New tile article goes here -->
            <div class="tile">
              <div class="tile-inner up-and-over-doors-type-grid">                
                <img src="@/img/services/garages/up-and-over/up-and-over-canopy.png" alt="">
                <div class="tile-text up-and-over-doors-type-grid">
                  <h2 class="article-info-headline">Canopy Doors </h2>
                  <p class="article-info-body-text up-and-over-doors-type-grid">
                    <ul>
                      <li>
                        The canopy garage door is the simplest and most common type of up and over garage door mechanism, its name derived from the fully opened garage door panel protruding about a third forward of the sub frame to form a canopy. It gives the maximum drive-through width when open and is the easiest to install on site. The door is balanced and assisted by torsion springs positioned above the door panel on the head of the fixing frame. 
                      </li>
                      <br/>
                      <li>
                        Canopy doors can be automated successfully using a good quality ‘bow arm converter’ and a reasonably powerful operator.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>

            <div class="tile">
              <div class="tile-inner up-and-over-doors-type-grid">
                <img src="@/img/services/garages/up-and-over/up-and-over-canopy2.jpg" alt="">
                <div class="tile-text up-and-over-doors-type-grid">
                  <h2 class="article-info-headline">Retractable Up & Over Garage Doors</h2>
                  <p class="article-info-body-text up-and-over-doors-type-grid">
                    <ul>
                      <li>
                        The retractable up and over door, its name derived from the garage door panel retracting fully into the garage when open. It is the mechanism that will convert easily and safely into remote control electric operation with the least equipment required. The drive-through width on a retractable mechanism is always reduced as the lifting arms sit in between the sub-frame when the door is opened so be careful when limited for width that you do not restrict access too much. Doors available in a variety of sizes and styles to suit any property.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>
            
          </div>
        </div>
      </Grid>
    </section>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

</style>