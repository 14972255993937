<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers - Doors
        </h2>
        <p>
          We are proud to be partnered with <em>Comp Door</em> for our range of composite doors. We are also a leading supplier for <em>Hormann</em> front doors. We offer a wide range of UPVC doors to compliment your existing window styles and colour. All of our doors offer peace of mind with security and quality in mind. We are proud to be partnered with Comp Door for our range of composite doors. We are also a leading supplier for Hormann front doors.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>