<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Simple design built for easy access</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Side hung doors are usually a pair of hinged doors that can come in a variety of materials – timber, steel, GRP etc. Also, they can have two different width doors if access is an issue. Space is required at the front of the garage to allow the doors to open.
                      
                      <br/><br/>
                      Speak to a member of our sales team today to arrange a quote for your next side hinged garage door.                     
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid garage-side-hinged-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

  <div class="">      
    <!-- Side Hinged doors -->
    <section class="image-text floors">
      <Grid class="pt2" center middle>
        <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
          <div class="tile-container side-hinged-doors-type-grid">

            <!-- New tile article goes here -->
            <div class="tile">
              <div class="tile-inner side-hinged-doors-type-grid">                
                <img src="@/img/services/garages/side-hinged/side-hinge-traditional.jpg" alt="">
                <div class="tile-text side-hinged-doors-type-grid">
                  <h2 class="article-info-headline">Traditional Doors </h2>
                  <p class="article-info-body-text side-hinged-doors-type-grid">
                    <ul>
                      <li>
                        We supply and fit a wide range of traditional Side Hinged garage doors that combine robust and reliable performance with simple personal access. They open outwards and are usually  manufactured with equal sized leaves, 50/50 split. 1/3 – 2/3 leaf configurations are also a hit with this style door, a fantastic solution for garages that require frequent pedestrian access. These side hinged garage doors are available with a lever handle inside and out, providing easy access at all times. 
                      </li>
                      <br/>
                      <li>
                        Side Hinged garage doors are available in a wide range of steel and timber door styles. Steel Side Hinged doors come factory finished in white as standard, with a choice of optional colours & wood effects. There is a choice of single skin and insulated doors to keep your garage warm in the winter and cooler during the summer months.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>

            <div class="tile">
              <div class="tile-inner side-hinged-doors-type-grid">
                <img src="@/img/services/garages/side-hinged/side-hinge-timber.jpg" alt="">
                <div class="tile-text side-hinged-doors-type-grid">
                  <h2 class="article-info-headline">Timber Side Hinged Doors</h2>
                  <p class="article-info-body-text side-hinged-doors-type-grid">
                    <ul>
                      <li>
                        Timber Side Hinged doors are supplied with a base stain as standard, with the option of a selection of stain or paint finishes. These doors provide extra space inside the garage and are a great solution for garages where access to wall space or utility boxes is required near the door entrance. Since the doors do not require tracks or panels that protrude into the garage, the required maintenance for these doors is low. The lack of lifting gears means there is significantly less moving parts than other garage door types. No springs, cables or gears to maintain and replace at all. The doors we supply and fit are only of the very highest quality Cedar Wood or Idigbo timber.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>
            
          </div>
        </div>
      </Grid>
    </section>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

</style>