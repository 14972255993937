<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Designed for garages that have limited space</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Sectional garage doors are distinguished by equal-height sections with precisely aligned ribbing and panelling. The sections are shaped so perfectly that the transitions are practically invisible when the door is closed. A sectional door, as the name implies, is made-up from separate panels. This allows for exceptionally smooth operation both manually and when electrically operated.                     
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid garage-sectional-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

  <div class="">      
    <!-- Sectional doors -->
    <section class="image-text floors">
      <Grid class="pt2" center middle>
        <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
          <div class="tile-container sectional-doors-type-grid">

            <!-- New tile article goes here -->
            <div class="tile">
              <div class="tile-inner sectional-doors-type-grid">                
                <img src="@/img/services/garages/sectional/overhead.jpg" alt="">
                <div class="tile-text sectional-doors-type-grid">
                  <h2 class="article-info-headline">Overhead Sectional Doors</h2>
                  <p class="article-info-body-text sectional-doors-type-grid">
                    <ul>
                      <li>
                        The sectional door creates a solid 42mm wall of panelling when closed and offer great insulation properties, sealed with a rubber seal bottom, top and sides. The sectional garage door can be fitted behind or between the brickwork opening depending on the customer requirements. To ensure a long-term resilience against the elements, hot galvanised steel is used for sectional door panels and frames. Sections are embossed and coated with a plastic polyester primer to provide the finest protection system available. All Sectional doors are supplied pre-finished in a variation of colours and are ready-to-fit any sized garage opening. Door and frame colours are vast. The doors can be unique in design with the availability of having windows built into the panels to allow light to penetrate into the garage when the door is closed. Sectional doors come with a range of thickness, single skin, 42mm & 67mm insulated panels. Matching side doors are also available. 
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>

            <div class="tile">
              <div class="tile-inner sectional-doors-type-grid">
                <img src="@/img/services/garages/sectional/sliding.jpg" alt="">
                <div class="tile-text sectional-doors-type-grid">
                  <h2 class="article-info-headline">Side Sliding Sectional Door</h2>
                  <p class="article-info-body-text sectional-doors-type-grid">
                    <ul>
                      <li>
                        Sectional doors also come as a side sliding door, giving the option to free up the ceiling space. In contrast to up-and-over doors, sectional doors or roller garage doors, this type of door opens to the side instead of upwards. This is especially advantageous if, for example, the garage ceiling has to remain free to store items or the door leaf cannot be stowed underneath or fixed to the ceiling due to construction reasons sloped roof, low lintel or obstructive drainpipes.
                      </li>
                    </ul>
                  </p>
                </div> 
              </div>
            </div>
            
          </div>
        </div>
      </Grid>
    </section>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

  
</style>