<template>
        
  <!-- Services Boxes -->
  <section id="services" class="home">
    <div class="container">
      <h4 class="lymer-door-title">
        At Lymers we know nothing seems more important than your front door, it defines the look of your home and you rely on it for security and protection from the elements. Lymers are proud to be partnered with Comp Door for our range of composite doors. We are also a leading supplier for Hormann and Ryterna front doors as well as offering a wide range of Rehau UPVC doors to compliment your existing window styles and colour. 
      </h4>
      <div class="row">
        <div class="services-grid">
          <article class="services-grid-article">
            <div class="upvc-door-bg">
            </div>
            <div class="services-grid-article-text-container full-blue">
              <h3>
                UPVC Doors
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Blog-UPVC-Doors" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

          <article class="services-grid-article">
            <div class="aluminium-door-bg">
            </div> 
            <div class="services-grid-article-text-container full-blue">
              <h3>
                Aluminium Doors
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Blog-Real-Aluminium-Door" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

          <article class="services-grid-article">
            <div class="horman-door-bg">
            </div>  
            <div class="services-grid-article-text-container full-blue">
              <h3>
                Hormann Doors
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Blog-Hormann_Doors" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>
          
          <article class="services-grid-article">
            <div class="ryterna-door-bg">
            </div> 
            <div class="services-grid-article-text-container full-blue">
              <h3>
                Ryterna Doors
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Blog-Ryterna-Doors" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
.lymer-door-title {
  color: #104086;
  margin-bottom: 2rem;
  text-align: center;
}

.upvc-door-bg {
  background-image: url("@/img/services/doors/suppliers/rehau-door.jpg");
  background-size: cover;
  background-position: center;
  height: 19rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.aluminium-door-bg {
  background-image: url("@/img/services/doors/suppliers/real-aluminium-door.jpg");
  background-size: cover;
  background-position: center;
  height: 19rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.horman-door-bg {
  background-image: url("@/img/services/doors/suppliers/hoorman-door.jpg");
  background-size: cover;
  background-position: center;
  height: 19rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.ryterna-door-bg {
  background-image: url("@/img/services/doors/suppliers/ryterna-door.jpg");
  background-size: cover;
  background-position: center;
  height: 19rem;
  width: -webkit-fill-available;
  width: -moz-available;
}
</style>