<template>
  <div>
  <!-- Footer -->
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >
      <!-- 1st Footer Row -->
      <div class="container foot">
        <div class="footer__widgets">
          <div class="row footer-contact-grid-container">
            <!-- 1st Column contact -->
            <div class="col-lg-4 col-md-6">
              <img class="client-logo"
                src="@/img/branding/header-logo-white.svg"
                alt="Lymers logo"
              />
            </div>
            <!-- 2nd Column CK Logo -->
            <div class="text-center">
              
              <div class="widget widget-about-us text-left text-sm-center text-lg-center">
                <h4 class="text-center"><b><u>Visit Us</u></b></h4>
                <div class="footer-visit-grid">
                  <div>
                    <h5><b><u>Leek</u></b></h5>
                    <p>
                      <a href="https://www.google.co.uk/maps/place/Lymer+Doors+Ltd/@53.0974519,-2.0324493,15z/data=!4m6!3m5!1s0x487a40a2e7de6c43:0x25f074818d872a75!8m2!3d53.0974519!4d-2.0324493!16s%2Fg%2F1tjf9s_2" target="_blank" class="footer-text">
                      Unit 5, Lymer Business Park,<br/>
                      Barnfield Road Industrial Estate,<br/>
                      Leek, ST13 5QG (Head Office)                 
                      </a>
                      
                    </p>
                    <p>
                      <b>
                        Monday - Friday: 7am - 5pm <br/>
                        Saturday: 8am - 2pm
                      </b>
                    </p>

                    <a
                      href="mailto:sales@lymerdoors.co.uk"
                      class="widget-about-us__email"
                      >sales@lymerdoors.co.uk</a
                    >
                    <a href="tel:01538 388727" class="widget-about-us__phone"
                      >01538 388727</a
                    >
                  </div>               
                  
                  <div>
                    <h5><b><u>Stafford</u></b></h5>
                    <p>
                      <a href="https://www.google.co.uk/maps/dir/53.0552075,-2.25432/6+Drummond+Rd,+Stafford+ST16+3HJ/@52.9426126,-2.388708,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x487a7185d140f1ad:0x4266a28776ef1ee8!2m2!1d-2.115318!2d52.8209112?entry=ttu" target="_blank" class="footer-text">
                      Unit 6, Drummond Road,<br/>
                      Stafford, ST16 3HJ                
                      </a>                    
                    </p>
                    <p>
                      <b>
                        Monday - Friday: 7am - 5pm <br/>
                        Saturday: 8am - 2pm
                      </b>
                    </p>

                    <a
                      href="mailto:sales@lymerdoors.co.uk"
                      class="widget-about-us__email"
                      >sales@lymerdoors.co.uk</a
                    >
                    <a href="tel:01785244559" class="widget-about-us__phone">
                      01785 244559
                    </a>
                  </div>
                </div>
                
              </div>
            </div>            
          </div>
        </div>
      </div>

      <!-- 2nd Footer Row -->
      <div class="footer__bottom">
        <div class="container" style="margin-bottom: 0px;">
          <div class="copyright-wrap text-center row">
            <div class="col-lg-3 col-md-3 text-center">
              
            </div>
            <div class="col-lg-6 col-md-6 text-center">
              <span class="copyright">
                &copy; 2024 - Lymers is a trading name for Lymer Doors Ltd. All rights reserved.<router-link to="Privacy" class="privacy-policy-link">
                  <span> Privacy Policy</span>
                </router-link>
              </span>
            </div>
            <div class="col-lg-4 col-md-6 text-center">
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
  </div>
</template>

<script>
import modal from "@/components/ContactModal.vue";

export default {
  name: "app",
  components: {
    modal // eslint-disable-line vue/no-unused-components
  },
  data() {
    return {
      isModalVisible: false,
      privacyPolicy: "/documents/HS Privacy Policy.pdf"
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style lang="scss"> 
@import '../styles/base/_variables.scss';
@import '../styles/base/_mixins.scss';

.privacy-policy-link:hover {
  color: $navbar-element-hover !important;
  cursor: pointer;
}

.footer {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vw;
  background-size: 100%;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: -fill-available;
  background-color: $primary-colour;

  @include breakpoint-small-mobile {
    height: fit-content;
  }

  @include breakpoint-mobile {
    height: fit-content;
  }

  @include breakpoint-tablet {
    height: fit-content;
  }

  @include breakpoint-large-tablet {
    height: fit-content;
  }

  @include breakpoint-above-large-tablet {
    height: fit-content;
  }

  .widget {

    @include breakpoint-small-mobile {
      margin-bottom: 1rem;
    }

    @include breakpoint-mobile {
      margin-bottom: 1rem;
    }

    @include breakpoint-tablet {
      margin-bottom: 1rem;
    }

    @include breakpoint-large-tablet {
      margin-bottom: 1rem;
    }
    
  }
}

.footer-visit-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include breakpoint-small-mobile {
    flex-direction: column;
  }

  @include breakpoint-mobile {
    flex-direction: column;
  }

  @include breakpoint-tablet {
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    flex-direction: row;
  }

  @include breakpoint-above-large-tablet {
    flex-direction: row;
  }

  div {
    margin:1rem;
  }
}

.footer-left-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  left: 0;
  height: 50vw;
  z-index: -3;
}

.footer-right-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  right: 0;
  height: 50vw;
  z-index: -3;
}

.client-logo {
  width: 34vw;
  height: 12vw;

  @include breakpoint-small-mobile {
    width: 52vw;
    height: 12vw;
  }

  @include breakpoint-mobile{
    width: 52vw;
    height: 12vw;
  }
}

.footer-contact-grid-container{  
  @include breakpoint-large-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: row;
    align-content: center;
  }

  @include breakpoint-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

  @include breakpoint-large-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: row;
    align-content: center;
  }

  .col-md-6 {
    @include breakpoint-large-tablet{
      max-width: 33%!important;
    }
  }

}

.networking-grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 0.5em;

  @include breakpoint-large-tablet{
    column-count: 1 !important;
    display: table-row !important;
  }

  @include breakpoint-tablet{
    column-count: 1 !important;
    display: table-row !important;
  }

  @include breakpoint-mobile {
    column-count: 2 !important;
    display: flex !important;
    flex-direction: column!important;
  }
}

.footer-logo-container {
  flex: 1 0 40%;
  max-width: 100%;
  @include breakpoint-small-mobile {
    flex: 1 1 40%;
    padding: 1.5vw;
  }
  @include breakpoint-mobile {
    flex: 1 1 20%;
    padding: 1.5vw;
  }
  @include breakpoint-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }
  @include breakpoint-large-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }

  @include breakpoint-small-laptop  {
    flex: 1 0 100%;
  }
}

.footer-logo {
  width: 20vw;
  cursor: pointer;
  height: auto;

  @include breakpoint-small-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-large-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-small-laptop  {
    width: 10rem;
  }
}

.footer__bottom {
  z-index: 5;
  padding-bottom: 0em;
  margin-top: 2vw;

  @include breakpoint-small-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include breakpoint-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-tablet {
    margin-top: 0;
    padding-bottom: 2em;
  }

  @include breakpoint-large-tablet {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-large-laptop {
    margin-top: 0;
  }

  container {
    @include breakpoint-tablet {
      margin: 0px auto;
    }

    @include breakpoint-large-tablet {
      margin: 0px auto;
    }
  }
}

</style>