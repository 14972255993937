<template>
  <div class="lymer-services-container garages garage-repair-section">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="lymer-services-grid garage-maintenance-services-bg side sm-90 d50 l40">
                </div>
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Garage Door Repairs</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Here at Lymer Doors we carry out any garage door repair that’s needed. Parts can break, wear out or they can get out of alignment. If your garage door is not working, we have the expertise to advise you and fix the issue. Our fully trained and experienced technician will assess the work and offer you the best solution.
                    <br/>
                    <h6>Our fully trained and experienced workforce can fix many common issues.</h6>
                      <ul class="lymer-service-ul ">
                        <li>
                          Rollers that will not stay in track
                        </li>
                        <li>
                          Broken springs
                        </li>
                        <li>
                          Tangled or broken cables
                        </li>
                        <li>
                          Automatic garage door won’t open or close
                        </li>
                      </ul>                    
                    </p>              
                  </ul>
                  
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.garage-repair-section {
  margin-bottom:2rem!important;
}
</style>