<template>
  <!-- Image Divider Section -->
  <Section class="industrial-para-background">
    <div class="para-side-by-side">
      <div class="side">
        <div class="welcome-intro-container">
          <h2>
            Speak to a member of our sales team today to arrange a quote for your next industrial door
          </h2>
          <p>
            We have a dedicated sales team who are on hand to help guide you through the process of modernising your home. With years of experience of helping all of our customers, our sales advisors will help you to choose the right style and design to suit your budget. 
            <br/>
            <br/>
            You can also visit our showrooms in Leek and Stafford where you can see our extensive range of products and our sales team can help you make the best choice for your home.
          </p>
          <div class="mt-16 lymer-service-button-container ">                    
            <router-link to="Contact" class="services-grid-article-button">
              <!-- <button class="client-button">Read More</button> -->
              <div class="client-button-container lymer-service-button">
                <button class="client-button">
                  <svg viewBox="0 0 180 60" class="border">
                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                    <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                  </svg>
                  <span>Contact Us</span>
                </button>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="side">

      </div>          
    </div>
  </Section>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.industrial-para-background{
  background-image: linear-gradient(to right, #061730, transparent), url("@/img/services/industrial/industrial-para.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: fit-content;

  @include breakpoint-small-mobile {
    height: fit-content;
  }

  @include breakpoint-mobile {
    height: fit-content;
  }

  @include breakpoint-tablet {
    height: fit-content;
  }

  @include breakpoint-large-tablet {
    height: fit-content;
  }

  @include breakpoint-laptop {
    padding: 1em 4rem!important;
  }

  @include breakpoint-large-laptop {
    padding: 1em 4rem!important;
  }

  .welcome-intro-container {
    width: 50%;
    @include breakpoint-small-mobile {
      width: 100%;
      padding: 1rem;
    }

    @include breakpoint-mobile {
      width: 100%;
      padding: 1rem;
    }

    @include breakpoint-tablet {
      width: 100%;
      padding: 1rem;
    }

    @include breakpoint-large-tablet {
      width: 100%;
      padding: 1rem;
    }
  }
}

.para-side-by-side {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: fit-content;
    overflow: hidden;

    @include breakpoint-small-mobile {
      height: fit-content;
    }

    @include breakpoint-mobile {
      height: fit-content;
    }

    @include breakpoint-tablet {
      height: fit-content;
      flex-direction: column;
    }

    @include breakpoint-large-tablet {
      height: fit-content;
    }

    @include breakpoint-above-large-tablet {
      padding-left: 2rem;
      height: fit-content;
    }

    .side {          
      align-self: flex-start;
      padding: 0rem 0px;

      @include breakpoint-small-mobile {
      }

      @include breakpoint-mobile {
      }

      @include breakpoint-tablet {
      }

      @include breakpoint-large-tablet {
      } 

      @include breakpoint-large-laptop {
        &.l40 {
          width: 40% !important;
        }
        
        &.l60 {
          width: 60% !important;
        }
      }

      &.l40 {
        width: 40%;
      }

      &.l60 {
        width: 60%;
      }

      &.news-text-pad {
        padding: 2rem 4rem 0rem 4rem;
      }

      &.d50 {
        width: 50%;
      } 
      
      &.sm-90 {
        width: 90%;

        @include breakpoint-laptop {
          width: 50% !important;
        }
      }

      &.sm-70 {
        width: 70%;
      }
      
      .what-we-do-sub-title {
        width: auto;
        padding: 0em 2rem;
      }
      .what-we-do-body-text {
        width: auto;
        padding: 0rem 4rem 0rem 0rem;
        li {
          margin: 1rem;
          @include breakpoint-small-mobile {
            margin: 1rem 0rem;
          }
          @include breakpoint-mobile {
            margin: 1rem 0rem;
          }           
        }

        em {              
          font-weight: bold;
          color: #ff7c00!important;
        }
      }
      .contact-button-container {
        padding: 0rem 1rem;
      }
    }
  }

</style>