<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side-repair-service">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Garage Repair Service</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Here at Lymer Doors we carry out any garage door repair that’s needed.
                      <br/><br/>
                      Parts can break, wear out or they can get out of alignment. If your garage door is not working, we have the expertise to advise you and fix the issue. Our fully trained and experienced technician will assess the work and offer you the best solution.
                      <br/><br/>
                      Our fully trained and experienced workforce can fix many common issues.
                    </p>
                      <ul class="text-left">
                        <li>
                          Rollers that will not stay in track.
                        </li>
                        <li>
                          Automatic garage door won’t open or close
                        </li>
                        <li>
                          Broken springs
                        </li>
                        <li>
                          Tangled or broken cables
                        </li>
                        <li>
                          Broken locks
                        </li>
                      </ul>
                    <p>
                      We can replace your garage door if it is beyond repair. Visit our garage doors page to find out about our range of garage doors.  
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid garage-repair-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

  
</style>