<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/contact/contact-hero.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>
        <div class="news-page">
          <div class="news-page-intro-container">
            <div class="news-page-text-container">
              <h2>Sliding gate</h2>
              <h4 class="news-page-subtext">
              </h4>      
            </div>      
          </div>

          <div class="lymer-services-container compdoor">
            <!-- When you buy CompDoor Section -->
            <div class="what-we-do-container lymer-service blog">
              <div class="pt2 what-we-do-grid">
                <div class="services-cont">
                  <div class="service-inverted what-we-do side lymer-service">
                    <div class="center">
                      <div class="blog-side-by-side blog-sliding-gate">
                        <div class="side lymer-service-text-pad sm-90 d50 l60">
                          <ul class="what-we-do-body-text lymer-service">
                            <p class="lymer-service-body-text">
                              What the customer wanted.
                              <br/>
                              What we did.
                              <br/>
                              New install of bespoke aluminium sliding gate fully automated with Nice Robus 600 sliding gate motor, Nice wireless keypads and safety Photocells. Finished in Painswick Grey.        
                            </p>              
                          </ul>                        
                        </div>
                        <div class="lymer-services-grid sliding-gate-blog-bg side">
                        </div>
                      </div>              

                    </div>
                  </div>              
                </div>
              </div> 
            </div>          
          </div>

        </div>
      </div>
    </main>
  </body>
</template>

<script>
    export default {
    name: "News",
    components: {
      }
    };

</script>

<style lang="scss">

</style>
