<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the previous up and over garage doors Lymers have designed and fitted for our customers
    </h2>
    <div class="services-grid garage-grid">
      <article class="services-grid-article garage-up-and-over-image one">
      </article>

      <article class="services-grid-article garage-up-and-over-image two">
      </article>

      <article class="services-grid-article garage-up-and-over-image three">
      </article>
      
      <article class="services-grid-article garage-up-and-over-image four">
      </article>

      <!-- <article class="services-grid-article garage-up-and-over-image five">
      </article>
      
      <article class="services-grid-article garage-up-and-over-image six">
      </article>

      <article class="services-grid-article garage-up-and-over-image seven">
      </article>
      
      <article class="services-grid-article garage-up-and-over-image eight">
      </article> -->
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.garage-up-and-over-image {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/garages/up-and-over/1.JPG");
  }
  &.two {
    background-image: url("@/img/services/garages/up-and-over/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/garages/up-and-over/5.jpg");
  }
  &.four {
    background-image: url("@/img/services/garages/up-and-over/6.jpg");
  }
  &.five {
    
  }
  &.six {
    
  }
  &.seven {
    
  }
  &.eight {
    
  }
}
</style>