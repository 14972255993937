<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the garages Lymers have designed and fitted for our customers
    </h2>
    <div class="services-grid garage-grid">
      <article class="services-grid-article garage-art one">
      </article>

      <article class="services-grid-article garage-art two">
      </article>

      <article class="services-grid-article garage-art three">
      </article>
      
      <article class="services-grid-article garage-art four">
      </article>

      <article class="services-grid-article garage-art five">
      </article>
      
      <article class="services-grid-article garage-art six">
      </article>

      <article class="services-grid-article garage-art seven">
      </article>
      
      <article class="services-grid-article garage-art eight">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.garage-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/garages/1.jpg");
  }
  &.two {
    background-image: url("@/img/services/garages/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/garages/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/garages/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/garages/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/garages/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/garages/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/garages/8.jpg");
  }
}
</style>