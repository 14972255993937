<template>
  <div class="news-page">
    <div class="news-page-intro-container">
      <div class="news-page-text-container">
        <!-- <h2>See the latest news and information from all of the team here at Lymers.</h2>
        <h4 class="news-page-subtext">You will find everything from details regarding our latest sales, helpful tips to look after your garages, windows & doors, as well as news and information about all the goings on here at Lymers. Click on any of the individual articles to find out more information. 
        </h4>       -->
      </div>      
    </div>

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container gates-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner gates-type-grid">                
                  <img src="@/img/services/gates/aluminium.jpg" alt="">
                  <div class="tile-text gates-type gates-type-grid">
                    <h2 class="article-info-headline">Aluminium </h2>
                    <p class="article-info-body-text gates-type-grid">
                      We offer a wide range of sliding & swing aluminium gates, complete with a 10-year Guarantee – covering UV discolouration, product build, components, accessories, and all materials. Aluminium gates are low maintenance, high-quality profiles that are easy to clean with a simple water and light detergent, wipe away dirt and grime with a soft cloth. Lightweight, strong and corrosion resistant – Using Aluminium Alloy 6060, this ensures inherent rust and corrosion resistance, with a lightweight construction, putting less strain on parts and automation equipment.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner gates-type-grid">
                  <img src="@/img/services/gates/composite.jpg" alt="">
                  <div class="tile-text gates-type gates-type-grid">
                    <h2 class="article-info-headline">Composite </h2>
                    <p class="article-info-body-text gates-type-grid">
                      We manufacture a range of Composite Driveway Gates, Composite Sliding Gates, Composite Side Gates, and Composite Railings. Composite gates produce a long lasting, durable and extremely low maintenance type of gate. There is no risk of warps, splitting or rot and no need to treat or stain the gate. We have a choice of coloured composite cladding which is set within a steel frame in a colour of your choice.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner gates-type-grid">
                  <img src="@/img/services/gates/wrought-iron.jpg" alt="">
                  <div class="tile-text gates-type gates-type-grid">
                    <h2 class="article-info-headline">Wrought iron</h2>
                    <p class="article-info-body-text gates-type-grid">
                      We offer a huge range of wrought iron gates and metal gate types such as Driveway Gates, Sliding Gates, Garden Gates, Side gates. We manufacture here at our premises; the gates are then galvanised & powder coated to a colour of your choice. All our wrought iron products are bespoke to any design to fulfil your requirements. 
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner gates-type-grid">
                  <img src="@/img/services/gates/timber.jpg" alt="">
                  <div class="tile-text gates-type gates-type-grid">
                    <h2 class="article-info-headline">Timber </h2>
                    <p class="article-info-body-text gates-type-grid">
                      Enjoy a bespoke entrance to your home with a hand-crafted wooden gate. We can offer an individual style tailored to your requirements that will complement the look of your home.Timber gates can be left untreated so they will weather naturally to blend in with the surroundings, or they can be painted any colour at any point in their life. Wooden gates can also be stained or covered with bespoke coatings! Timber will deteriorate over time if it isn’t properly cared for and maintained. Wood shrinks when the weather is hot enough and absorbs moisture when it rains, which may affect the infrastructure when left unchecked. Wooden gates will need regular maintenance to look as good as new.
                    </p>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


