<template>   
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the previous industrial doors have designed and fitted for our customers
    </h2>
    <div class="image-mosaic mt-32">
      <div class="card card-tall card-wide industrial-art one"></div>
      <div class="card card-tall card-wide industrial-art two"></div>
      <div class="card card-tall card-wide industrial-art three"></div>
      <div class="card card-tall card-wide industrial-art four"></div>
      <div class="card card-tall card-wide industrial-art five"></div>
      <div class="card card-tall card-wide industrial-art six"></div>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.industrial-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/industrial/1.jpg");
  }
  &.two {
    background-image: url("@/img/services/industrial/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/industrial/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/industrial/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/industrial/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/industrial/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/industrial/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/industrial/8.jpg");
  }
}
</style>