import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Garages from "../views/Garages.vue";
import GaragesUpAndOver from "../views/Garage-UpAndOver.vue";
import GarageSideHingedDoors from "../views/Garage-SideHingedDoors.vue";
import GarageSectionalDoors from "../views/Garage-SectionalDoors.vue";
import GarageRollerDoors from "../views/Garage-RollerDoors.vue";
import GarageBuilds from "../views/Garage-Builds.vue";
import Windows from "../views/Windows.vue";
import DomesticDoors from "../views/DomesticDoors.vue";
import Gates from "../views/Gates.vue";
import VerandaConservatoryCarports from "../views/Veranda-Conservatory-Carports.vue";
import Industrial from "../views/Industrial.vue";
import Electrical from "../views/Electrical.vue";
import News from "../views/News.vue";
import Contact from "../views/Contact.vue";
import Privacy from "../views/Privacy.vue";


// Blogs pages
import SlidingGateBlog from "../views/blogs/sliding-gate-blog-29-11-23.vue";
import HormannDoorsBlog from "../views/blogs/blog-hormann-doors.vue";
import RyternaDoorsBlog from "../views/blogs/blog-ryterna-doors.vue";
import BlogRehauWindows from "../views/blogs/blog-rehau-casement-windows.vue";
import BlogUPVCDoors from "../views/blogs/blog-upvc-doors.vue";
import BlogFlushFitWindows from "../views/blogs/blog-flush-fit-windows.vue";
import BlogTiltTurnWindows from "../views/blogs/blog-tilt-turn-windows.vue";
import BlogShapedFramenWindows from "../views/blogs/blog-shaped-frame-windows.vue";
import BlogFrontDoorTransformation from "../views/blogs/blog-front-door-transformation.vue";
import BlogCompDoor from "../views/blogs/blog-comp-door.vue";
import BlogRealAluminiumDoor from "../views/blogs/blog-real-aluminium-doors.vue";
import BlogUPVCFrenchDoors from "../views/blogs/blog-upvc-french-doors.vue";
import BlogMakingWayForNewDoor from "../views/blogs/blog-new-door.vue";
import BlogPatioSlidingDoor from "../views/blogs/blog-patio-sliding-doors.vue";
import BlogTiltTurn from "../views/blogs/blog-tilt-turn.vue";
import BlogUPVCConservatoryRoof from "../views/blogs/blog-upvc-conservatory-roof.vue";
import BlogIcothermRoof from "../views/blogs/blog-icotherm-roof.vue";

//Import all other pages to route here

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Garages',
        name: 'Garages',
        component: Garages
    },
    {
        path: '/Garages-Up-And-Over',
        name: 'Garages-Up-And-Over',
        component: GaragesUpAndOver
    },
    {
        path: '/Garage-Side-Hinged-Doors',
        name: 'Garage-Side-Hinged-Doors',
        component: GarageSideHingedDoors
    },
    {
        path: '/Garage-Sectional-Doors',
        name: 'Garage-Sectional-Doors',
        component: GarageSectionalDoors
    },
    {
        path: '/Garage-Roller-Doors',
        name: 'Garage-Roller-Doors',
        component: GarageRollerDoors
    },
    {
        path: '/Garage-Builds',
        name: 'Garage-Builds',
        component: GarageBuilds
    },    
    {
        path: '/Windows',
        name: 'Windows',
        component: Windows
    },
    {
        path: '/Domestic-Doors',
        name: 'Domestic-Doors',
        component: DomesticDoors
    },   
    {
        path: '/Gates',
        name: 'Gates',
        component: Gates
    },
    {
        path: '/Veranda-Conservatory-Carports',
        name: 'Veranda-Conservatory-Carports',
        component: VerandaConservatoryCarports
    },
    {
        path: '/Industrial',
        name: 'Industrial',
        component: Industrial
    },
    {
        path: '/Electrical',
        name: 'Electrical',
        component: Electrical
    }, 
    {
        path: '/News',
        name: 'News',
        component: News
    },
    {
        path: '/Contact',        
        name: 'Contact',
        component: Contact,          
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/Blog-Sliding-Gate',
        name: 'SlidingGateBlog',
        component: SlidingGateBlog
    },
    {
        path: '/Blog-Hormann_Doors',
        name: 'HormannDoorsBlog',
        component: HormannDoorsBlog
    },
    {
        path: '/Blog-Ryterna-Doors',
        name: 'RyternaDoorsBlog',
        component: RyternaDoorsBlog
    },
    {
        path: '/Blog-Rehau-Windows',
        name: 'BlogRehauWindows',
        component: BlogRehauWindows
    },
    {
        path: '/Blog-UPVC-Doors',
        name: 'BlogUPVCDoors',
        component: BlogUPVCDoors
    },
    {
        path: '/Blog-Flush-Fit-Windows',
        name: 'BlogFlushFitWindows',
        component: BlogFlushFitWindows
    },
    {
        path: '/Blog-Tilt-Turn-Windows',
        name: 'BlogTiltTurnWindows',
        component: BlogTiltTurnWindows
    },
    {
        path: '/Blog-Shaped-Frame-Windows',
        name: 'BlogShapedFramenWindows',
        component: BlogShapedFramenWindows
    },
    {
        path: '/Blog-Front-Door-Transformation',
        name: 'BlogFrontDoorTransformation',
        component: BlogFrontDoorTransformation
    },
    {
        path: '/Blog-Comp-Door',
        name: 'BlogCompDoor',
        component: BlogCompDoor
    },
    {
        path: '/Blog-Real-Aluminium-Door',
        name: 'BlogRealAluminiumDoor',
        component: BlogRealAluminiumDoor
    },
    {
        path: '/Blog-UPVC-French-Doors',
        name: 'BlogUPVCFrenchDoors',
        component: BlogUPVCFrenchDoors
    },
    {
        path: '/Blog-Making-Way-For-New-Door',
        name: 'BlogMakingWayForNewDoor',
        component: BlogMakingWayForNewDoor
    },
    {
        path: '/Blog-Patio-Sliding-Door',
        name: 'BlogPatioSlidingDoor',
        component: BlogPatioSlidingDoor
    },
    {
        path: '/Blog-Tilt-Turn',
        name: 'BlogTiltTurn',
        component: BlogTiltTurn
    },
    {
        path: '/Blog-UPVC-Conservatory-Roof',
        name: 'BlogUPVCConservatoryRoof',
        component: BlogUPVCConservatoryRoof
    },
    {
        path: '/Blog-Icotherm-Roof',
        name: 'BlogIcothermRoof',
        component: BlogIcothermRoof
    },


    


    
    // Add new routes here
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition){
        return{top: 0};
    },
    routes
})

export default router
