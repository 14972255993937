<template>
  <!-- Header -->
  <main class="headline">
      <div class="text-center row">
        <div class="col-lg-4 col-md-4 text-center">
          <span class="headline-body">
            <a
              href="tel:01538388727 "
              class=""
              >
              <img class="contact-logo" src="@/img/phone-white.png" alt="Phone Logo">
              01538 388727 </a
            >
          </span>
        </div>
        <div class="col-lg-4 col-md-4 text-center">
          <span class="headline-body">
            <a
              href="mailto:sales@lymerdoors.co.uk"
              class=""
              >
              <img class="contact-logo" src="@/img/email-white.png" alt="Email Logo">
              sales@lymerdoors.co.uk</a
            >
          </span>
        </div>

        <!-- Socials -->
        <div class="nav__socials col-lg-4 col-md-4 text-center">
          <div class="socials right">
            <a
              href="https://www.facebook.com/lymerdoors"
              class="social social-facebook"
              aria-label="facebook"
              title="facebook"
              target="_blank"
            >
              <i class="ui-facebook"></i>
            </a>
            <a
              href="https://uk.linkedin.com/company/lymer-doors-limited"
              class="social social-linkedin"
              aria-label="linkedin"
              title="linkedin"
              target="_blank"
            >
              <i class="ui-linkedin"></i>
            </a>
            <a
              href="https://www.instagram.com/lymerdoors/?hl=en"
              class="social social-instagram"
              aria-label="instagram"
              title="instagram"
              target="_blank"
            >
              <i class="ui-instagram"></i>
            </a>
          </div>
        </div>

      </div>
  </main>
  <!-- end navigation -->
</template>

