<template>
<div class="contact-hero contact-side-by-side">
  <div class="contact-container side" style="padding-bottom: 3em;">    
    <div class="gradient-box-container">
      <div class="form-container gradient-box mt-48 mb-48">
        <h2 class="contact-header-text">Contact a member of our team today</h2>
        <Grid center>
          <Column :column-width="70" :below-tablet-width="100">
            <Grid pad :class="['contact-form', errors ? 'errors' : '']">
              <Column :column-width="50" :below-laptop-width="100">
                <div class="form-group">
                  <label v-if="!nameError" for="name">Name</label>
                  <label v-if="nameError" class="errorLabel" for="name"
                    >* You must enter a name</label
                  >

                  <input
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    :class="[nameError ? 'error' : '']"
                    v-model="name"
                    required
                  />
                </div>
              </Column>
              <Column :column-width="50" :below-laptop-width="100">
                <div class="form-group">
                  <label for="company">Company</label>
                  <input
                    type="text"
                    v-model="company"
                    placeholder="Company Name"
                    name="company"
                  />
                </div>
              </Column>

              <Column :column-width="50" :below-laptop-width="100">
                <div class="form-group">
                  <label v-if="!emailError" for="email">Email</label>
                  <label v-if="emailError" class="errorLabel" for="email"
                    >* You must enter either a phone or valid email</label
                  >
                  <input
                    type="email"
                    v-model="email"
                    :class="[emailError ? 'error' : '']"
                    placeholder="Email"
                    name="email"
                  />
                </div>
              </Column>

              <Column :column-width="50" :below-laptop-width="100">
                <div class="form-group">
                  <label v-if="!telephoneError" for="telephone">Telephone</label>
                  <label v-if="telephoneError" class="errorLabel" for="telephone"
                    >* You must enter either a phone or valid email</label
                  >
                  <input
                    type="tel"
                    :class="[telephoneError ? 'error' : '']"
                    placeholder="Phone Number"
                    name="telephone"
                    v-model="telephone"
                  />
                </div>
              </Column>

              <Column>
                <div class="form-group-full">
                  <label v-if="!messageError" for="message">Your Message</label>
                  <label v-if="messageError" class="errorLabel" for="message"
                    >* You must enter a message</label
                  >
                  <textarea
                    name="message"
                    placeholder="Your Message"
                    :class="[messageError ? 'error' : '']"
                    rows="8"
                    v-model="message"
                  ></textarea>
                </div>
              </Column>

              <Column>
                <div class="form-group" style="margin-bottom: 2rem;">
                  <div id="recaptcha" class="g-recaptcha"></div>
                </div>
              </Column>

              <Column :column-width="100">
                <a
                  :class="['contact-button button', sending ? 'sending' : '']"
                  href="#"
                  @click.prevent="sendMessage"
                  >{{ sendButtonText }}</a
                >

                <p
                  :class="[
                    'label',
                    errors || sendFailure ? 'error' : 'success',
                    !sendSucceeded && !errors && !sendFailure ? 'hide' : ''
                  ]"
                >
                  &nbsp;
                  <span v-if="errors"
                    >Please fix the issues above and click
                    <b>Send Message</b> again</span
                  >
                  <span v-if="sendSucceeded"
                    >Your message was successfully sent. We will get back to you
                    shortly</span
                  >

                  <span v-if="sendFailure">{{ sendFailure }}</span>
                </p>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </div>
     
    </div>    
  </div>

  <div class="side map">
    <!-- Google Map -->
    <Grid>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9582.838028369386!2d-2.0324493!3d53.0974519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487a40a2e7de6c43%3A0x25f074818d872a75!2sLymer%20Doors%20Ltd!5e0!3m2!1sen!2suk!4v1684587945495!5m2!1sen!2suk" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" aria-hidden="false" tabindex="0">></iframe>
    </Grid>
    <ContactImageGrid />
  </div>

</div>
  
</template>

ContactImageGrid.vue


<script>
import ContactImageGrid from "@/components/ContactImageGrid.vue";
export default {
  name: "contact",
  components: {
    ContactImageGrid
  },
  data() {
    return {
      splashImageUrl: "/images/backgrounds/map-background.jpg",
      name: "",
      company: "",
      email: "",
      telephone: "",
      message: "",
      nameError: false,
      emailError: false,
      telephoneError: false,
      messageError: false,
      sending: false,
      sendFailure: "",
      sendSucceeded: false
    };
  },
  computed: {
    errors() {
      return (
        this.nameError ||
        this.emailError ||
        this.telephoneError ||
        this.messageError
      );
    },
    sendButtonText() {
      return this.sending ? "Sending..." : "Send Message";
    }
  },
  methods: {
    validEmail(email) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    isEmptyText(input) {
      return input == null || (typeof input === 'string' && input.trim() === "");
    },
    sendMessage() {
      // If we are already sending...
      if (this.sending)
        // Ignore
        return;

      // Store this
      let me = this;

      // Clear send errors
      this.sendFailure = "";
      this.sendSucceeded = false;

      // Check we have a name
      this.nameError = this.isEmptyText(this.name);

      // Email or telephone must be provided
      this.emailError = this.telephoneError =
        !this.validEmail(this.email) && this.isEmptyText(this.telephone);

      // Check we have a message
      this.messageError = this.isEmptyText(this.message);

      // If we have any errors...
      if (this.errors) {
        // Scroll to top of form
        document.querySelector(".above-contact-form").scrollIntoView({
          behavior: "smooth",
          block: "end" // scroll to bottom of target element
        });

        // Stop there
        return;
      }

      // Get reCAPTCHA response
      const recaptchaResponse = this.getRecaptchaResponse();
      if (!recaptchaResponse) {
        alert('Please complete the reCAPTCHA');
        return;
      }

      // Good to go... try sending email and setting sending status
      this.sending = true;
      const axios = require('axios').default;

      // First, verify reCAPTCHA with the server
      axios
        .post("/verify-recaptcha", { recaptchaResponse })
        .then(function(recaptchaResponse) {
          if (recaptchaResponse.data.success) {
            // reCAPTCHA verified successfully, proceed to send email
            return axios.post("/sendemail", {
              name: me.name,
              company: me.company,
              email: me.email,
              telephone: me.telephone,
              message: me.message
            });
          } else {
            // reCAPTCHA verification failed
            me.sending = false;
            me.sendFailure = "reCAPTCHA verification failed. Please try again.";
            throw new Error("reCAPTCHA verification failed");
          }
        })
        .then(function(response) {
          // No longer sending
          me.sending = false;

          console.log("got response");
          console.log(response);

          // Make sure response says "Sent ok :)"
          if (response.data === "Sent ok :)") {
            // All good
            me.sendSucceeded = true;

            // Clear all fields
            me.name = me.company = me.email = me.telephone = me.message = "";
            grecaptcha.reset(); // Reset reCAPTCHA
          } else {
            // Doh... unknown response
            me.sendFailure = "Unknown response from mail sender server.";
          }
        })
        .catch(function(error) {
          // No longer sending
          me.sending = false;

          console.log("got error");
          console.log(error.response);

          // Doh... show error
          me.sendFailure = me.isEmptyText(error.response?.data)
            ? error.response?.statusText
            : error.response?.data;
        });
    },
    getRecaptchaResponse() {
      return grecaptcha.getResponse();
    }
  },
  mounted() {
    // Render the reCAPTCHA widget after the component is mounted
    grecaptcha.render('recaptcha', {
      sitekey: '6LdZWioqAAAAAATgwFNlslmmD3YfW3ARK1pW5dPt',
    });
  },
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .contact-side-by-side {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;

    @include breakpoint-small-mobile {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    @include breakpoint-mobile {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    @include breakpoint-tablet {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    @include breakpoint-large-tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
    }

    .side {          
      align-self: flex-start;
      padding: 3rem 0px;

      @include breakpoint-small-mobile {
        padding: 0rem 0px;
      }

      @include breakpoint-mobile {
        padding: 0rem 0px;
      }

      @include breakpoint-tablet {
        padding: 0rem 0px;
      }

      @include breakpoint-large-tablet {
        padding: 0rem 1rem;
      } 

      @include breakpoint-large-laptop {
        &.l40 {
          width: 40% !important;
        }
        
        &.l60 {
          width: 60% !important;
        }
      }

      &.d50 {
        width: 50%;
      } 
      
      &.sm-90 {
        width: 90%;

        @include breakpoint-laptop {
          width: 50% !important;
        }
      }

      &.sm-70 {
        width: 70%;
      }

      &.map {
        width: 40vw;
        @include breakpoint-small-mobile {
          width: 80vw;
        }

        @include breakpoint-mobile {
          width: 80vw;
        }

        @include breakpoint-tablet {
          width: 100vw;
        }
      }
      
      .what-we-do-sub-title {
        width: auto;
        padding: 0em 2rem;
      }
      .what-we-do-body-text {
        width: auto;
        padding: 2rem 2rem;
        &.low-pad {              
          padding: 1rem 2rem;

          @include breakpoint-small-mobile {
            padding: 0rem 0rem;
          }

          @include breakpoint-mobile {
            padding: 0rem 0rem;
          }

          @include breakpoint-tablet {
            padding: 1rem 0rem;
          }
        }
        li {
          margin: 1rem;
          @include breakpoint-small-mobile {
            margin: 1rem 0rem;
          } 
          
          @include breakpoint-mobile {
            margin: 1rem 0rem;
          } 
        }

        em {              
          font-weight: bold;
          color: #ff7c00!important;
        }
      }
      .contact-button-container {
        padding: 0rem 1rem;      
      }
    }
  }

  .contact-button {
    padding: 1rem;
    background-color: $secondary-color;
    border-radius: 10px;
    border: solid 0.2rem $secondary-color-contrast;
    box-shadow: 0px 0px 13px 0px $secondary-color; 
    color: $secondary-color-contrast;
    font-weight: bold;    
  }

  .contact-button:hover {
    cursor: pointer;
    background-color: $primary-colour;
    border: solid 0.2rem $primary-contrast-colour;
    color: $primary-contrast-colour;
  }

 .gradient-box-container {
  width: 45vw;
  z-index: -1;
  margin: -5px; /* !important */
  border-radius: 1em;
  background-color: transparent;
  height: fit-content;

  @include breakpoint-small-mobile {
    width: 85vw;
  }

  @include breakpoint-mobile {
    margin-top: 5vw;
    margin: 0px;
    width: 85vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 5vw;
    margin: 0px;
    width: 100%;
  }

  .gradient-box {
    display: block;
    align-items: center;
    width: 45vw;
    margin: auto;
    position: relative;
    padding: 2em;
    box-sizing: border-box;
    color: #FFF;
    background-color: $primary-colour;
    background-clip: padding-box;
    border: solid 5px transparent;
    border-radius: 0!important;
    height: fit-content;
    overflow: hidden;

    @include breakpoint-small-mobile {
      width: 100%;
      padding: 1em;
    }

    @include breakpoint-mobile {
      width: 100%!important;
    }
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      width: 100%!important;
    }
  }
}

.message-button-container {
  margin-top: 2vw;
  margin-bottom: 4vw;
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  @include breakpoint-mobile {
    margin-top: 10vw;
    margin-bottom: 10vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 10vw;
    margin-bottom: 10vw;
  }
}

.contact-hero {   
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 55%;
  height: fit-content;

  @include breakpoint-mobile {
    margin-top: 14vw;
    background-position: right top;    
    background-size: 250% 25%;
    padding-bottom: 5vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 11vw;
    background-position: right top;    
    background-size: 180% 25%;
  }

  .contact-header-text{
    margin: 2rem 0em;
    // font-size: 5rem;
    color: $primary-contrast-colour;

    @include breakpoint-small-mobile {
      font-size: 2rem;
    }

    @include breakpoint-mobile {
      margin-top: 0rem;
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    @include breakpoint-tablet {
      margin-top: 0rem;
      font-size: 8vw;
      margin-bottom: 4vw;
    }

    @include breakpoint-laptop {
      font-size: 3rem;
    }

    @include breakpoint-large-laptop {
      margin-top: 0em;
    }
  }    

  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }  

  /* Button resets and style */
  button {
    margin: 15px auto;
    font-family: "Montserrat";
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    padding: 15px 20px;
    border: 0px solid #000;   
  }

  .hs-button {
    margin: 15px auto !important;;
    font-family: "Montserrat"!important;
    font-size: 20px!important;
    color: #ffffff!important;
    cursor: pointer!important;
    border-radius: 100px!important;
    padding: 15px 20px!important;
    border: 0px solid #000!important; 
    @include breakpoint-mobile {
      width: 50vw!important;
      margin-left: auto!important;
      margin-right: auto!important;
      position: relative!important;
      display: block!important;
    }
  }

}
</style>