<template>
  <div class="softvideo-container">
    <h2 class="softvideo-title">
      Aluminium Sliding Gate
    </h2>
    <p class="softvideo-sub-title">
      A great installation by our gate team, gate includes full automation and entry system.
    </p>

      <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https:////player.vimeo.com/video/902476805?h=4f63edfc4a&loop=0&muted=1&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" muted="1" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Lymers - Sectional Door"></iframe></div>
  </div>  
</template>

<script>
export default ({
  components: {
  }
})
</script>  

<style lang="scss">

</style>
