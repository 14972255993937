<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/contact/contact-hero.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>
          <div class="blogpage">
            <div class="blogpage-intro-container">
              <div class="blogpage-text-container">
                <h2>Front Door Combination Transformation</h2>
                <h4 class="blogpage-subtext">The old UPVC door was replaced with a composite door from Comp Door. This has completely changed the look of the door and improved the security and insulation properties, whilst keeping the inside white keeps the hallway light if the size of the glass is reduced.
                </h4>
              </div>      
            </div>

            <div class="">      
              <!-- Project Navigation Tile -->
              <section class="image-text floors">
                <Grid class="pt2" center middle>
                  <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                    <div class="tile-container hormann-doors-type-grid blog-transform">

                      <!-- New tile article goes here -->
                      <div class="tile">
                        <div class="tile-inner hormann-doors-type-grid blog-transform">                
                          <img src="@/img/news/18-11-23-c.jpg" alt="">
                          <div class="tile-text front-door-transformation">
                            <h2 class="article-info-headline">Before</h2>
                          </div> 
                        </div>
                      </div>

                      <div class="tile">
                        <div class="tile-inner hormann-doors-type-grid blog-transform">
                          <img src="@/img/news/18-11-23-b.jpg" alt="">
                          <div class="tile-text front-door-transformation">
                            <h2 class="article-info-headline">After</h2>
                          </div> 
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </Grid>
              </section>
            </div>

          </div>
      </div>
    </main>
  </body>
</template>

<script>
    export default {
    name: "News",
    components: {
      }
    };

</script>

<style lang="scss">

</style>
