<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers provides a first class service to all of our customers.
        </h2>
        <p>
          Lymers have been engaged in the supply and installation of garage doors and pre-fab garages of all types and designs for 30 years. We are a leading business with experienced fitters covering the whole of The Midlands.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>