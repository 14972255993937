<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/news/hero/rehau-blog-hero.jpg" alt="">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Standard Casement</h2>
              <h4 class="blogpage-subtext">Casement windows are the most popular style of replacement window in the UK – an extremely versatile window that will suit all styles of home ensuring you can make a real statement.
              </h4>
            </div>      
          </div>

        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side rehau-windows-blog">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            Featuring fixed panes, top opening fanlights and side opening lights, casement windows can be combined to create an almost limitless range of styles.
                            <br/>
                            Lymers supply and install Rehau windows as they are a market leading profile offering a window that offers style and security.
                            <br/>
                            Why Lymers choose Rehau windows:
                            <ul class="text-left">
                              <li>
                                70mm frame depth - front to back - on all products
                              </li>
                              <li>
                                Internally glazed casement
                              </li>
                              <li>
                                28mm single leg bead, sculptured or flat
                              </li>
                              <li>
                                Highly advanced secure locking system
                              </li>
                              <li>
                                Fully feature grooved - all colours
                              </li>
                              <li>
                                Designed to accept the latest hardware innovations
                              </li>
                              <li>
                                Astragal bar option available
                              </li>
                              <li>
                                Galvanised steel reinforced to British Standards
                              </li>
                              <li>
                                Products enable compliance with the latest building regulations
                              </li>
                              <li>
                                Window Energy Ratings - A, B and C to all products
                              </li>
                            </ul>
                            <div class="mt-16 lymer-service-button-container ">                    
                              <router-link to="Contact" class="services-grid-article-button">
                                <!-- <button class="client-button">Read More</button> -->
                                <div class="client-button-container lymer-service-button">
                                  <button class="client-button">
                                    <svg viewBox="0 0 180 60" class="border">
                                      <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                      <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                    </svg>
                                    <span>Contact us</span>
                                  </button>
                                </div>
                              </router-link>
                            </div>                    
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid rehau-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <div class="">          
          <!-- Project Navigation Tile -->
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Features</h2>
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container features-grid blog-type-grid three">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/handles.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          High security auto lock Handles are available in a range of Colours
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/multi-point.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          BS7950 - Mulit Point Shootbolt window locking system
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/sash.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Run through sash horns are available to create a more traditional look.
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <!-- Colour Tiles Options Grid -->
        <div class="">
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Colour Options</h2>           
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container colour-options-grid blog-type-grid three">

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/white.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/golden-oak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Golden Oak and Golden Oak on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/rosewood.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Rosewood and Rosewood on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/ChartwellGreeny.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Chartwell Greeny on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/ironoak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Irish Oak Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/whitewoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/creamwoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Cream Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/blackonwhite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Black on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/anthracite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Anthracite Grey on White
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side small blog-casement-windows">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Bay & bow windows</h2>
                        <h4>Create a wonderful sense of space and light for your home</h4>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            Bay and bow windows are an elegant and attractive external feature in any property whilst internally creating a sense of space and light.
                            <br/><br/>
                            Bay and bow windows are created using casement windows which are connected using a strong, structural bay pole assembly or corner posts (for square bay windows) and as with all REHAU products are available in a range of finishes and colours.                
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid baywindow-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <WindowsParallax/>

      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
    export default {
    name: "News",
    components: {
      WindowsParallax,
      }
    };

</script>

<style lang="scss">
</style>
