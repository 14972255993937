<template>
        
  <!-- Services Boxes -->
  <section id="services" class="home">
    <div class="container">
      <div class="row">
        <div class="services-grid">
          <article class="services-grid-article tile-overlay garage-services-bg"> 
            <div class="services-grid-article-text-container">
              <h3>
                Garage Doors
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Garages" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

          <article class="services-grid-article tile-overlay windows-services-bg"> 
            <div class="services-grid-article-text-container">
              <h3>
                Windows
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Windows" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

          <article class="services-grid-article tile-overlay gates-services-bg"> 
            <div class="services-grid-article-text-container">
              <h3>
                Gates
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Gates" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>
          
          <article class="services-grid-article tile-overlay industrial-services-bg"> 
            <div class="services-grid-article-text-container">
              <h3>
                Industrial
              </h3>
              <div class="services-grid-article-button-container">
                <router-link to="Industrial" class="services-grid-article-button">
                  <!-- <button class="client-button">Read More</button> -->
                  <div class="client-button-container">
                    <button class="client-button">
                      <svg viewBox="0 0 180 60" class="border">
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                        <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                      </svg>
                      <span>Read More</span>
                    </button>
                  </div>
                </router-link>
              </div>                    
            </div>
          </article>

        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">

</style>