<template>
  <div class="lymer-design-your-own-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Design your own composite doors</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      Using our latest online design tool, you can explore the entire range of windows and doors that we offer, and create the look that you want for your new windows and doors.                   
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <a href="https://compdoor.portal.bm-touch.co.uk/launch/lymers121" target="_blank" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Find out more</span>
                        </button>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="lymer-services-grid windows-design-your-own side sm-90 d50 l40">
                  <img src="@/img/services/doors/doors_design_your_own.png" alt="ipad design tool for your doors">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.lymer-services-grid {
  background: $primary-colour;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    padding: 30px 0px;
  }

  

  &.tools {
    column-count: 4;
    column-gap: 0em;
    padding: 3rem 1rem !important;    
    display: grid;
  }
}

.lymer-service-button-container {
  position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  &.contact {
    left: unset;
    top: unset;
  }

  @include breakpoint-small-mobile {
    position: relative;
  }

  @include breakpoint-mobile {
    position: relative;
  }

  @include breakpoint-tablet {
    position: relative;
    margin: 2rem auto;
    margin-top:1rem;
  }

  @include breakpoint-large-tablet {
    position: relative;
    width: 100vw;
    margin: 2rem auto;
    margin-top:1rem;
  }
  @include breakpoint-above-large-tablet {
    position: relative;
    margin: 2rem auto;
    margin-top:1rem;
  }
}

.lymer-service-button {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91C9FF;
  outline: none;
  color: $secondary-color;
  transition: 1s ease-in-out;

  @include breakpoint-small-mobile {
    width: auto;
    height: auto;
  }

  @include breakpoint-mobile {
    width: auto;
    height: auto;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
}


</style>