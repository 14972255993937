<template>
  <div class="lymer-services-container compdoor">

    <!-- When you buy CompDoor Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="compdoor-side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">CompDoor</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      When you buy a <em>Comp Door</em> composite entrance door, you’ll instantly benefit from years of expert engineering combined to create a premium door with market-leading performance at a competitive price. We’ve partnered with industry-leading innovators to bring together the very best the market has to offer. Every detail has been carefully considered and the finest components selected to make the UK’s best quality composite entrance door.
                      <ul class="text-left">
                        <li>
                          Over 250 colour combinations inside & out
                        </li>
                        <li>
                          48mm solid timber core
                        </li>
                        <li>
                          Outstanding 1.4 W/m²K u-Value
                        </li>
                        <li>
                          Highly advanced secure locking system
                        </li>
                        <li>
                          Chamfered or sculptured frames
                        </li>
                        <li>
                          All locks compliant PAS24: 2016
                        </li>
                        <li>
                          ABS security cylinder as standard
                        </li>
                      </ul>                    
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Blog-Comp-Door" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Read More</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid compdoor-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>


  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.compdoor {
  margin-top: 4rem!important;

  @include breakpoint-small-mobile {
    margin-top: 0rem!important;
  }

  @include breakpoint-mobile {
    margin-top: 0rem!important;
  }

  @include breakpoint-large-tablet {
    margin-top: 0rem!important;
  }
}

.lymer-service {

    &.blog {
      padding-top: 0vw;
      padding-bottom: 2vw;
    }    
  }

.lymer-services-grid {
  background: $primary-colour;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 991px) {
    padding: 30px 0px;
  }

  &.compdoor-services-bg {
    background-image: url("@/img/services/windows/suppliers/CompDoorLarge.png");
    background-size: cover;
    background-position: center;

    @include breakpoint-large-tablet {
      height: inherit;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
    }
  }

  &.icotherm-blog-bg {
    background-image: url("@/img/news/11-01-24-blog/icotherm2.jpg");
    background-size: cover;
    background-position: top;
    @include breakpoint-small-mobile {
    }
    @include breakpoint-mobile {
    }
    @include breakpoint-tablet {
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.new-door-blog-bg {
    background-image: url("@/img/news/11-01-24-blog/new-door.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
    }
    @include breakpoint-mobile {
    }
    @include breakpoint-tablet {
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.upvc-conservatory-roof-bg {
    background-image: url("@/img/news/11-01-24-blog/UPVC-ConservatoryRoof-hero.jpg");
    background-size: cover;
    background-position: center;
    height: 70vh;
    @include breakpoint-small-mobile {
      background-size: contain;
      background-position: center;
      background-position-y: center;
      background-attachment: fixed;
      height: 8rem;
      background-position-y: 10rem;
      background-repeat: no-repeat;
    }
  }

  &.upvc-conservatory-roof-side-bg {
    background-image: url("@/img/news/11-01-24-blog/UPVC-ConservatoryRoof2.jpg")!important;
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
    }
    @include breakpoint-mobile {
    }
    @include breakpoint-tablet {
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.real-aluminium-door-blog-bg {
    background-image: url("@/img/news/11-01-24-blog/real-2.jpg")!important;
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.icospan-bg {
    background-image: url("@/img/news/11-01-24-blog/icospan.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.rehau-blog-bg {
    background-image: url("@/img/news/11-12-23-rehau.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.upvc-blog-bg {
    background-image: url("@/img/news/11-12-23-upvc.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.baywindow-blog-bg {
    background-image: url("@/img/news/11-12-23-baywindow.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.upvc-catalogue-blog-bg {
    background-image: url("@/img/news/11-12-23-upvc-catalogue.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }    

  &.tools {
    column-count: 4;
    column-gap: 0em;
    padding: 3rem 1rem !important;    
    display: grid;
  }

  &.sliding-gate-blog-bg {
    background-image: url("@/img/news/18-11-23.jpg");
    background-size: cover;
    background-position: center;
    width: 100% !important;

    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.comp-door-blog-bg {
    background-image: url("@/img/news/10-01-24-blog/comp-door.png");
    background-size: cover;
    background-position: center;
    width: 100%!important;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.why-comp-door-blog-bg {
    background-image: url("@/img/news/10-01-24-blog/compdoorblog-why.jpg");
    background-size: cover;
    background-position: center;
    @include breakpoint-small-mobile {
      height: 15rem;
    }

    @include breakpoint-mobile {
      height: 25rem;
    }

    @include breakpoint-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
    @include breakpoint-above-large-tablet {
      height: inherit;
      background-position: center;
      background-repeat: no-repeat;
    }
  }  


  
}

.lymer-service-button-container {
  position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  &.contact {
    left: unset;
    top: unset;
  }

  @include breakpoint-small-mobile {
    position: relative;
  }

  @include breakpoint-mobile {
    position: relative;
  }

  @include breakpoint-tablet {
    position: relative;
    margin: 2rem auto;
    margin-top:1rem;
  }

  @include breakpoint-large-tablet {
    position: relative;
    width: 100vw;
    margin: 2rem auto;
    margin-top:1rem;
  }
  @include breakpoint-above-large-tablet {
    position: relative;
    width: 50%;
    margin: 2rem auto;
    margin-top:1rem;
  }
}

.lymer-service-button {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91C9FF;
  outline: none;
  color: $secondary-color;
  transition: 1s ease-in-out;

  @include breakpoint-small-mobile {
    width: auto;
    height: auto;
  }

  @include breakpoint-mobile {
    width: auto;
    height: auto;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
}

.lymer-services-container {
  width: 80vw;
  padding: 0vw;
  margin: 0 auto;

  @include breakpoint-small-mobile {
    width: 100vw;
  }

  @include breakpoint-mobile {
    width: 100vw;
  }

  .lymer-service {
    padding-top: 5vw;
    padding-bottom: 2vw;
    
    @include breakpoint-above-large-tablet {
      padding-top: 0vw;
      padding-bottom: 0vw;
    }

    @include breakpoint-large-tablet {
      padding-bottom: 0vw;
    }

    @include breakpoint-tablet {
      padding: 0vw;
    }

    .lymer-service {
      margin: 0px;
      padding: 0rem;
      border-radius: 0!important;

      @include breakpoint-small-mobile {
        margin: 1rem 0rem;
      }

      @include breakpoint-mobile {
        margin: 1rem 0rem;
      }

      @include breakpoint-tablet {
        margin: 0rem 0vw;
        padding: 0rem;
      }
      

      .lymer-service-headline {
        font-weight: bold;
        text-align: left;
        @include breakpoint-small-mobile {
          text-align: center;
        }

        @include breakpoint-mobile {
          text-align: center;
        }
      }

      .lymer-service-sub-headline {
        font-weight: bold;
        text-align: left;
        @include breakpoint-small-mobile {
          text-align: center;
        }

        @include breakpoint-mobile {
          text-align: center;
        }
      }

      .lymer-service-body-text {
        padding-top:1rem;
        text-align: left;
        @include breakpoint-small-mobile {
          text-align: center;
        }

        @include breakpoint-mobile {
          text-align: center;
        }
      }

      .compdoor-side-by-side {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 50rem;
        overflow: hidden;

        @include breakpoint-small-mobile {
          flex-direction: column;
        }

        @include breakpoint-mobile {
          flex-direction: column;
        }

        @include breakpoint-tablet {
          flex-direction: column;
          height: 52rem;
        }

        @include breakpoint-large-tablet {
          height: 55rem;
        }

        .side {          
          align-self: flex-start;
          padding: 0rem 0px;
          overflow: hidden;
          height: -webkit-fill-available;

          @include breakpoint-small-mobile {
          }

          @include breakpoint-mobile {
          }

          @include breakpoint-tablet {
          }

          @include breakpoint-large-tablet {
          } 

          @include breakpoint-large-laptop {
            &.l40 {
              width: 40% !important;
            }
            
            &.l60 {
              width: 60% !important;
            }
          }

          &.lymer-service-text-pad {
            padding: 2rem 4rem 0rem 4rem;          

            @include breakpoint-small-mobile {
              padding: 0rem 1rem 0rem 1rem;
            }

            @include breakpoint-mobile {
              padding: 0rem 1rem 0rem 1rem;
            }

            @include breakpoint-laptop {
              padding: 0rem 3rem 0rem 3rem;
            }
          }

          &.d50 {
            width: 50%;
          } 
          
          &.sm-90 {
            width: 90%;          

            @include breakpoint-small-mobile {
              width: 100% !important;
            }

            @include breakpoint-mobile {
              width: 100% !important;
            }

            @include breakpoint-laptop {
              width: 50% !important;
            }
          }

          &.sm-70 {
            width: 70%;
          }

          .text-left {
            @include breakpoint-small-mobile {
              text-align: center !important;
            }

            @include breakpoint-mobile {
              text-align: center !important;
            }
          }
          
          .what-we-do-sub-title {
            width: auto;
            padding: 0em 2rem;
          }
          .what-we-do-body-text {
            width: auto;
            padding: 0rem;          

            @include breakpoint-small-mobile {
              padding: 0rem 1rem;
            }

            @include breakpoint-mobile {
              padding: 0rem 1rem;
            }

            @include breakpoint-desktop {
              padding: 0rem 0rem 0rem 0rem;
            }

            li {
              margin: 1rem;
              @include breakpoint-small-mobile {
                margin: 1rem 0rem;
              }

              @include breakpoint-mobile {
                margin: 1rem 0rem;
              }           
            }

            em {              
              font-weight: bold;
              color: $secondary-color!important;
            }
          }
          .contact-button-container {
            padding: 0rem 1rem;
          }
        }
      }

      .blog-side-by-side {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        height: 48rem;
        overflow: hidden;

        @include breakpoint-small-mobile {
          flex-direction: column;
          height: fit-content;
        }

        @include breakpoint-mobile {
          flex-direction: column;
          height: fit-content;
        }

        @include breakpoint-tablet {
        }

        @include breakpoint-large-tablet {
        }
        
        &.small {
          height: 25rem;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
        }

        &.real-aluminium-blog {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 55rem;
          }
          @include breakpoint-above-large-tablet {
            height: 55rem;
          }
        }

        &.blog-tilt-turn {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 50rem;
          }
          @include breakpoint-above-large-tablet {
            height: 50rem;
          }
        }

        &.new-door {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 55rem;
          }
          @include breakpoint-above-large-tablet {
            height: 45rem;
          }

          .sm-90 {
            width: 100%!important;
          }
          .lymer-service-text-pad
          {
            padding: 2rem 4rem 2rem 4rem!important;
          }
        }

        &.blog-sliding-gate {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
            height: fit-content;
          }
          @include breakpoint-large-tablet {
            height: fit-content;
            flex-direction: column!important;
          }
          @include breakpoint-small-laptop {
            height: 20rem;
          }
          @include breakpoint-laptop {
            height: 25rem;
          }
          @include breakpoint-large-laptop {
            height: 20rem;
          }
          @include breakpoint-desktop {
            height: 20rem;
          }
          @include breakpoint-extra-large {
            height: 20rem;
          }
        }

        &.blog-casement-windows {
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
            height: fit-content;
          }
          @include breakpoint-large-tablet {
            height: fit-content;
            flex-direction: column!important;
          }
          @include breakpoint-small-laptop {
            height: 25rem;
          }
          @include breakpoint-laptop {
            height: 30rem;
          }
          @include breakpoint-large-laptop {
            height: 25rem;
          }
          @include breakpoint-desktop {
            height: 25rem;
          }
          @include breakpoint-extra-large {
            height: 25rem;
          }
        }

        &.rehau-windows-blog {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 60rem;
          }
          @include breakpoint-small-laptop {
            height: 60rem;
          }
          @include breakpoint-laptop {
            height: 50rem;
          }
          @include breakpoint-large-laptop {
            height: 50rem;
          }
          @include breakpoint-desktop {
            height: 50rem;
          }
          @include breakpoint-extra-large {
            height: 50rem;
          }
        }

        &.upvc-roof {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 40rem;
          }
          @include breakpoint-above-large-tablet {
            height: 45rem;
          }
        }

        &.icotherm-roof {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 55rem;
          }
          @include breakpoint-above-large-tablet {
            height: 40rem;
          }
        }

        &.conservblog {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 55rem;
          }
          @include breakpoint-above-large-tablet {
            height: 40rem;
          }
        }

        &.blog-comp-door {
          height: fit-content;
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
          }
          @include breakpoint-large-tablet {
            height: 50rem;
          }
          @include breakpoint-above-large-tablet {
            height: 65rem;
          }
        }

        &.blog-upvc-door {
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
            height: fit-content;
          }
          @include breakpoint-large-tablet {
            height: fit-content;
            flex-direction: column!important;
          }
          @include breakpoint-small-laptop {
            height: 60rem;
          }
          @include breakpoint-laptop {
            height: 60rem;
          }
          @include breakpoint-large-laptop {
            height: 65rem;
          }
          @include breakpoint-desktop {
            height: 55rem;
          }
          @include breakpoint-extra-large {
            height: 50rem;
          }
        }

        &.blog-upvc-door-bay-section {
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
            height: fit-content;
          }
          @include breakpoint-large-tablet {
            height: fit-content;
            flex-direction: column!important;
          }
          @include breakpoint-small-laptop {
            height: 45rem;
          }
          @include breakpoint-laptop {
            height: 50rem;
          }
          @include breakpoint-large-laptop {
            height: 40rem;
          }
          @include breakpoint-desktop {
            height: 40rem;
          }
          @include breakpoint-extra-large {
            height: 40rem;
          }
        }

        &.blog-why-comp-door {
          @include breakpoint-small-mobile {
            height: fit-content;
          }
          @include breakpoint-mobile {
            height: fit-content;
          }
          @include breakpoint-tablet {
            flex-direction: column!important;
            height: fit-content;
          }
          @include breakpoint-large-tablet {
            height: fit-content;
            flex-direction: column!important;
          }
          @include breakpoint-small-laptop {
            height: 55rem;
          }
          @include breakpoint-laptop {
            height: 65rem;
          }
          @include breakpoint-large-laptop {
            height: 50rem;
          }
          @include breakpoint-desktop {
            height: 50rem;
          }
          @include breakpoint-extra-large {
            height: 45rem;
          }
        }

        .side {          
          align-self: flex-start;
          padding: 0rem 0px;
          overflow: hidden;
          height: -webkit-fill-available;

          @include breakpoint-small-mobile {
          }

          @include breakpoint-mobile {
          }

          @include breakpoint-tablet {
          }

          @include breakpoint-large-tablet {
          } 

          @include breakpoint-large-laptop {
            &.l40 {
              width: 40% !important;
            }
            
            &.l60 {
              width: 60% !important;
            }
          }

          &.lymer-service-text-pad {
            padding: 2rem 4rem 0rem 4rem;          

            @include breakpoint-small-mobile {
              padding: 1rem 1rem 0rem 1rem;
            }

            @include breakpoint-mobile {
              padding: 1rem 1rem 0rem 1rem;
            }

            @include breakpoint-laptop {
              padding: 0rem 3rem 0rem 3rem;
            }
          }

          &.d50 {
            width: 50%;
          } 
          
          &.sm-90 {
            width: 90%;

            @include breakpoint-small-mobile {
              width: 100%;
            }

            @include breakpoint-mobile {
              width: 100%;
            }

            @include breakpoint-laptop {
              width: 50% !important;
            }

            &.comp-door-blog-bg {
              width:100%!important;
            }
          }

          &.sm-70 {
            width: 70%;
          }
          
          .what-we-do-sub-title {
            width: auto;
            padding: 0em 2rem;
          }
          .what-we-do-body-text {
            width: auto;
            padding: 0rem;
            @include breakpoint-desktop {
              padding: 0rem 0rem 0rem 0rem;
            } 
            li {
              margin: 1rem;
              @include breakpoint-small-mobile {
                margin: 1rem 0rem;
              } 
              
              @include breakpoint-mobile {
                margin: 1rem 0rem;
              } 
            }

            em {              
              font-weight: bold;
              color: $secondary-color!important;
            }
          }
          .contact-button-container {
            padding: 0rem 1rem;
          }
        }
      }
    }
  }
}
</style>