<template>
  <div class="news-page">
    <div class="">      

      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container electrical-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner industrial-type-grid">                
                  <img src="@/img/services/electrical/1.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Consumer unit upgrades</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      Our electricians will assess your current electrical system and recommend the most suitable unit for your property, whether it be to meet current building regulations and safety standards, increase the power capacity of your home to handle the increasing demands of modern appliances and technology, or to simply be more energy efficient; reducing the energy bills and environmental impact of your home.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/electrical/2.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Additional socket points</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      Whether it be a new socket ring in your garage to power a new hobby, or an IP66 rated outdoor socket in your garden to conveniently run your gardening power tools without having to drag an extension lead from the house, Lymer Doors has you covered!
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/electrical/3.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Bespoke lighting solutions</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      With years of experience in lighting solutions our electricians will be able to consult, design and install, even the most complex lighting systems, including ambient smart lighting in the lounge, security lighting to protect your property or accent lighting to highlight its best features.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/electrical/4.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">CCTV systems</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      Protect your home or business with a full HD camera system tailored specifically to your needs by our experienced installers. We offer solutions including full colour night vision, remote monitoring via Android and IOS apps and wireless solar solutions to even protect the areas that cables cannot reach.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/electrical/5.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Automation</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      Do you want your front gates to open at the click of a button? Your electric garage door to open as your vehicle approaches it? Or perhaps the lights in your house to turn on as you enter the room? Nowadays, almost every aspect of your home or business can be automated, and the electrical department at Lymer Doors is here to turn your ideas into realities.
                    </p>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>

      <!-- <div class="news-page-intro-container">
        <div class="news-page-text-container">
          <h2>Maintenance and Servicing Contracts</h2>
          <h4 class="news-page-subtext">Lymer Doors can provide annual maintenance and service contracts to help you meet regulatory responsibilities & to save your organisation the hassle of having to remember to have your industrial doors serviced.  It also means that if a repair is required, we will commit to respond as a priority, providing a speedy service to get your doors up and running again. 
          </h4>      
        </div>      
      </div> -->

    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


