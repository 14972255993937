<template>
  <div class="news-page">
    <div class="">      

      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container industrial-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner industrial-type-grid">                
                  <img src="@/img/services/industrial/12.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Sectional Industrial Doors</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      A perfect solution for industrial environments, insulated sectional doors are made up of 40-80mm thick steel sandwich panels. They have excellent thermal insulation and provide a great solution for any operational requirement. The panels can be supplied with different window options and integrated personnel doors.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/industrial/4.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Roller Shutter Doors</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      Versatile designs with high quality steel or aluminium slats makes a roller shutter the calling card for your premises. Coloured finishes are also available upon request. Other options include:
                      <br/>
                      <ul>
                        <li>
                          Single skin, insulated or perforated lath
                        </li>
                        <li>
                          Electric operation or manual
                        </li>
                        <li>
                          Single & 3 phase supply, tube & shaft mounted motors
                        </li>
                      </ul>
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/industrial/10.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">High Speed Doors</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      High speed doors offer a fast action operation, improving your energy efficiency and productivity and significantly reducing airborne pollution and travel noise when compared to other types of industrial doors. Options may include radars, photo cells, induction loops and traffic light systems.
                    </p>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner industrial-type-grid">
                  <img src="@/img/services/industrial/11.jpg" alt="">
                  <div class="tile-text industrial-type-grid">
                    <h2 class="article-info-headline">Steel Hinged Doors</h2>
                    <p class="article-info-body-text industrial-type-grid">
                      We offer an extensive range of bespoke steel doors including personnel doors, security rated, fire exit and fire rated doors for external applications. We also offer a varied range of design options including louvre panels, glazing or solid infill panels incorporating DDA compliance requirements as needed. Steel hinged doors are available in a variety of colours and choice of hardware.
                    </p>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>

      <!-- <div class="news-page-intro-container">
        <div class="news-page-text-container">
          <h2>Maintenance and Servicing Contracts</h2>
          <h4 class="news-page-subtext">Lymer Doors can provide annual maintenance and service contracts to help you meet regulatory responsibilities & to save your organisation the hassle of having to remember to have your industrial doors serviced.  It also means that if a repair is required, we will commit to respond as a priority, providing a speedy service to get your doors up and running again. 
          </h4>      
        </div>      
      </div> -->

    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


