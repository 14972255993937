<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="lymer-services-grid fensa-services-bg side sm-90 d50 l40">
                </div>
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">What is FENSA?</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      FENSA is a government-authorised scheme that monitors building regulation compliance for replacement windows and doors. Every FENSA Approved Installer, from large national brands to small local companies, is assessed regularly by us to ensure its standards are continually maintained.
                    <br/>
                    <h6>Why do you need a FENSA certificate? It’s proof your installation:</h6>
                      <ul class="lymer-service-ul fensa">
                        <li>
                          Complies with Building Regulations
                        </li>
                        <li>
                          Is registered with the local council
                        </li>
                        <li>
                          Is backed by guarantee insurance
                        </li>
                      </ul>                    
                    </p>              
                  </ul>
                  
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

</style>