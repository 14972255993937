<template>
  <div class="softvideo-container">
    <h2 class="softvideo-title">
      Hormann Sectional Door
    </h2>
    <p class="softvideo-sub-title">
      Horman sectional garage door with wicket door supplied and fitted by Lymers.
    </p>
      <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/902479531?h=94484c8b61&loop=1&muted=1&title=0&byline=0&portrait=0" frameborder="0" muted="1" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Lymers - Sectional Door"></iframe></div>
  </div>  
</template>

<script>
export default ({
  components: {
  }
})
</script>  

<style lang="scss">

</style>