<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="about"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-splash garages-construction"></div>
        </section>
        <GarageConstructionIntro/>        
        <GarageConstructionService/>
        <GarageParallax/>
        <GaragesConstructionImageGrid/>
        <GarageRepairService/>
        <GaragesManuSlider/>
        
        <Reviews/>      
      </div>
    </main>
    <!-- end main wrapper -->
    

  </body>
</template>

<script>
import GarageConstructionIntro from '@/components/GarageConstructionIntro.vue'
import GarageConstructionService from '@/components/GarageConstructionService.vue'
import GarageRepairService from '@/components/GarageRepairService.vue'
import GaragesManuSlider from "@/components/GaragesManuSlider.vue";
import GaragesConstructionImageGrid from "@/components/GaragesConstructionImageGrid.vue";
import GarageParallax from "@/components/GarageParallax.vue";
import Reviews from "@/components/Reviews.vue";
export default {
  components:{
    GarageConstructionIntro,
    GarageConstructionService,
    GarageRepairService,
    GaragesManuSlider,
    GaragesConstructionImageGrid,
    GarageParallax,
    Reviews
  }
};

</script>

<style lang="scss">

@import '../styles/base/_mixins.scss';

// To be refactored outside of this style between custom and meettheteam.scss
  $primary-colour: #ff7600;
  $primary-colour-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c;
  $body-font: 'Montserrat', sans-serif;
  $heading-font: 'Montserrat', sans-serif;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .image-left{
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }

    }

    .image-right{
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }

    }

  .blue {
    background-image: linear-gradient(to top right, #15212f, #213851);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      left: 4vw;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      }      
    }
  }

  .blue-reverse {
    background-image: linear-gradient(to top right, #15212f, #213851);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

     img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      right: 4vw;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint-small-laptop {
        width: 42vw !important;
      } 
    }
  }

  .orange {
    background-image: linear-gradient(to top right, #f74d1a, #ffe154);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      left: 0;

      @include breakpoint-mobile {
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      right: 4vw;
      color: #1f344b;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      } 
    }
    
  }

  .orange-reverse {
    background-image: linear-gradient(to top right, #f74d1a, #ffe154);
    background-image: -moz-linear-gradient(top, #E01212, #000000);
    background-image: -ms-linear-gradient(top, #E01212, #000000);
    background-image: -o-linear-gradient(top, #E01212, #000000);
    background-image: -webkit-gradient(to top right, #E01212, #000000);

    img {
      transition: opacity 1s ease;
      opacity: 0.5;
      width: 25vw;
      position: absolute;
      display: flex;
      margin-left: auto;
      bottom: 0;
      right: 0;

      @include breakpoint-mobile{
        width: 60vw;
        border-bottom: solid 1vw white;
      }

      @include breakpoint-tablet{
        width: 60vw;
        border-bottom: solid 1vw white;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 40vw;
      //   border-bottom: solid 1vw white;
      // }
    }

    .glider-year {
      top: 4vw;
      left: 4vw;
      color: #1f344b;
    }

    .glider-content {
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint-small-laptop {
        width: 42vw !important;
      }       
    }
    
  }

  #glider {
    display: flex;
    z-index: 3;
    position: relative;

    @include breakpoint-small-mobile {
      display: none;
    }
    @include breakpoint-mobile{
      display: none;
    }

    @include breakpoint-tablet{
      display: none;
    }

    @include breakpoint-large-tablet{
      display: none;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   display: none;
    // }

    h4 {
      color: white !important;

      @include breakpoint-laptop{
        font-size: 1rem;
      }

      @include breakpoint-large-laptop{
        font-size: 1.2rem;
      }
      @media only screen and (min-width: 1025px) and (max-width: 1800px) {
        font-weight: 100;    
      }      
    }
  }

  .glider-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 50%;
    max-height: 35vw;
    min-height: 35vw;
    color: $primary-colour;
    overflow: hidden;
    transition: width 0.5s ease;
    
    
    &:before, &:after {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }

    &:after {
      opacity: 1;
      transition: opacity 0.5s ease;
    }

    &:before {
      z-index: 1;
      opacity: 0;
      transform: translate3d(0, 0, 0) translateY(50%);
      transition: opacity 0.5s ease, transform 0.5s ease;
      
    }

    &:hover {
      width: 80% !important;

      @include breakpoint-small-laptop {
        width: 90% !important;
      }
      img {
        opacity: 1;
      }
      
      
      &:after {
        opacity: 0;
      }

      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) translateY(0);
        transition: opacity 1s ease, transform 1s ease 0.25s;
        
      }
      
      .glider-content {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.75s ease 0.5s;
      }

      .glider-year{
        font-weight: bold;

        @include breakpoint-small-laptop {
          h4 {
            font-weight: normal;
          }
        } 
      }

      .glider-bg {
        filter: grayscale(0);
      }

    }

    &.Nat {
      .glider-content {
        @include breakpoint-large-laptop {
          width: 40vw;
        }        
      }
    }
  }

  .glider-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 2em !important;
    padding: 0.5em;
    opacity: 0;

    @include breakpoint-small-laptop {
      h4 {
        font-size: 1.4vw!important;
      }
    }

    @include breakpoint-extra-large {
      padding: 2em 0.5em;
    }

    p {
      margin-top: 3em !important;
    }
  }

  .glider-year {
    position: absolute;
    z-index: 1;
    
    p {
      font-size: 2.5vw;
      line-height: 1;
      font-weight: bold;
      font-style: $heading-font;
      text-shadow: 2px 2px black, 4px 4px #fff;
      text-align: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint-extra-large {
        font-size: 2.5vw!important;
      }
    }
  }

  .glider-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }

  #mobile-glider {
       
    .mobile-orange{
      background-image: linear-gradient(to top right, #f74d1a, #ffe154);
      background-image: -moz-linear-gradient(top, #E01212, #000000);
      background-image: -ms-linear-gradient(top, #E01212, #000000);
      background-image: -o-linear-gradient(top, #E01212, #000000);
      background-image: -webkit-gradient(to top right, #E01212, #000000);
    }

    .mobile-blue{
      background-image: linear-gradient(to top right, #15212f, #213851);
      background-image: -moz-linear-gradient(top, #E01212, #000000);
      background-image: -ms-linear-gradient(top, #E01212, #000000);
      background-image: -o-linear-gradient(top, #E01212, #000000);
      background-image: -webkit-gradient(to top right, #E01212, #000000);
    }
  }

  .mobile-glider-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    width: 50%;
    color: $primary-colour;
    overflow: hidden;
    transition: width 0.5s ease;
    height: 100% !important;

    @include breakpoint-small-mobile {
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-mobile{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-tablet{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

    @include breakpoint-large-tablet{
      display:block;
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }
    
    
    &:before, &:after {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }

    &:before {
      z-index: 1;      
    }

    &:hover {
      .mobile-glider-bg {
        filter: grayscale(0);
      }
    }

    .mobile-glider-image{
      @include breakpoint-small-mobile {
        width: 50%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }
      }      
    
      @include breakpoint-mobile {
        width: 50%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }

      @include breakpoint-tablet {
        width: 40%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }

      @include breakpoint-large-tablet {
        width: 40%;

        &.right{
          margin-left: 50%;
        }

        &.left{
          margin-right: 50%;
        }          
      }
    }
  }

  .mobile-glider-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 5em !important;
    margin-bottom: 2em !important;
    padding: 0.5em;

    @include breakpoint-small-mobile {
      margin-top: 0em !important;
      margin: 0em 2em;
    }

    @include breakpoint-mobile {
      margin-top: 0em !important;
      margin: 0em 2em;
    }

    @include breakpoint-tablet{
      margin-top: 0em !important;
      margin: 0em 2em;
      padding: 0em 0em 2em 0em;
    }

    @include breakpoint-large-tablet{
      margin-top: 0em !important;
      margin: 0em 2em;
      padding-bottom: 4em;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   margin-top: unset !important;
    // }   
    
    h1 {
      text-transform: uppercase;
      color: $primary-colour;
      font-size: 1.44rem;
      font-weight: normal;
    }

    h4 {
      color: White;
      font-size: 4vw;
      font-weight: normal;

      @include breakpoint-small-mobile {
        margin-top: 0em;
      }

      @include breakpoint-mobile {
        margin-top: 0em;
      }

      @include breakpoint-tablet{
        margin-top: 0em;
        font-size: 2.6vw;
      }

      @include breakpoint-large-tablet{
        margin-top: 0em;
        font-size: 2.6vw;
      }
    }

    p{
      color: $tertiary-color !important;
      font-size: 0.9em !important;
    }
  }

  .mobile-glider-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    @include breakpoint-small-mobile {
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-mobile {
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-tablet{
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }

    @include breakpoint-large-tablet{
      width: 45vw;
      left: 25vw;
      top: 30vw;
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   width: 45vw;
    //   top: unset;
    //   margin-top: -6vw;
    // }    
    
    p {
      font-size: 2.5vw;
      line-height: 1;
      font-weight: bold;
      font-style: "Montserrat", sans-serif;
      text-shadow: 2px 2px black, 4px 4px #fff;
      text-align: center;

      @include breakpoint-small-mobile {
        font-size: 8vw;
      }

      @include breakpoint-mobile {
        font-size: 8vw;
      }
      
      @include breakpoint-tablet{
        font-size: 6vw;
      }

      @include breakpoint-large-tablet{
        font-size: 4vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   font-size: 8vw;
      // }   
      
    }

    &.blue-text{
      color: #1f344b;
    }

    &.right{

      left: 73vw;

    }
  }

  .mobile-glider-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }

  .mobile-item-seperator{
    @include breakpoint-small-mobile {
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }
    @include breakpoint-mobile {
      //border-top: 1vh solid white; 
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    @include breakpoint-tablet{
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    @include breakpoint-large-tablet{
      margin: 0vw !important;
      display: block;
      height: 1vh;
      background-color: white;
    }

    display:none;
  
  }

  .grid-container{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 auto;
    
    @include breakpoint-mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint-tablet{
      grid-template-columns: repeat(1, 1fr);
    }
    // @media only screen and (min-width: 481px) and (max-width: 768px) {
    //   grid-template-columns: repeat(1, 1fr);
    // }

    .tile img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .tile:nth-child(1){
      grid-column: span 3;
      grid-row: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // }      
    }

    .tile:nth-child(2),
    .tile:nth-child(3){
      grid-column: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }

    .tile:nth-child(4),
    .tile:nth-child(5){
      grid-column: span 3;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
         width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }    
    .tile:nth-child(6){
      grid-column: span 3;
      grid-row: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }
    .tile:nth-child(7){
      grid-column: span 2;
      width: 49.65vw;
      @include breakpoint-mobile {
        width: 100vw;
      }

      @include breakpoint-tablet{
        width: 100vw;
      }
      // @media only screen and (min-width: 481px) and (max-width: 768px) {
      //   width: 100vw;
      // } 
    }
  }

  $primary-colour: #e15a2a;
  $primary-colour-hover: #f84100;
  $secondary-color: #162431;
  $tertiary-color: #f2ba5c; 

  /* Declate color shifting animation */
  @keyframes colorShift {
    0%, 100% {
        background: #e65c00;
    }
    33% {
      background: #ff0000;
    }
    66%{
      background: #ccb800;
    }
  }

  /* Declare border pulse animation */
  @keyframes borderPulse {
    0% {
      box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
      box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
  }


</style>
