<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/news/11-01-24-blog/french-door-hero.jpg" alt="">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>UPVC French doors</h2>
              <h4 class="blogpage-subtext">
                A french door manufactured from REHAU is a charming addition to any home or conservatory. The extensive opening allows unrestricted access to your home as well as maximum ventilation and practical entry to your garden or patio.Offering all the benefits of modern uPVC including noise reduction, low maintenance, safety, security and energy efficiency.
                <br/><br/>
                All our French doors come with double handles as standard and an optional opening restrictor to prevent the doors from swinging freely and causing damage. 
              </h4>
            </div>      
          </div>
        </div>

        <div class="">                
          <!-- Project Navigation Tile -->
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Colour Options</h2>           
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container colour-options-grid blog-type-grid three">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/white.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/golden-oak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Golden Oak and Golden Oak on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/rosewood.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Rosewood and Rosewood on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/ChartwellGreeny.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Chartwell Greeny on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/ironoak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Irish Oak Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/whitewoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/creamwoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Cream Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/blackonwhite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Black on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/anthracite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Anthracite Grey on White
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

      </div>
    </main>
  </body>
</template>

<script>
    export default {
    name: "News",
    components: {
      }
    };

</script>

<style lang="scss">
</style>
