<template>
  <div class="news-page">
    <div class="news-page-intro-container">
      <div class="news-page-text-container">
        <!-- <h2>See the latest news and information from all of the team here at Lymers.</h2>
        <h4 class="news-page-subtext">You will find everything from details regarding our latest sales, helpful tips to look after your garages, windows & doors, as well as news and information about all the goings on here at Lymers. Click on any of the individual articles to find out more information. 
        </h4>       -->
      </div>      
    </div>

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container garage-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner garage-type-grid">                
                  <img src="@/img/services/garages/9.jpg" alt="">
                  <div class="tile-text garage-type-grid">
                    <h2 class="article-info-headline">Up and Over Doors</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Garages-Up-And-Over" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner garage-type-grid">
                  <img src="@/img/services/garages/3.jpg" alt="">
                  <div class="tile-text garage-type-grid">
                    <h2 class="article-info-headline">Roller Doors</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Garage-Roller-Doors" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner garage-type-grid">
                  <img src="@/img/services/garages/6.jpg" alt="">
                  <div class="tile-text garage-type-grid">
                    <h2 class="article-info-headline">Sectional Doors</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Garage-Sectional-Doors" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner garage-type-grid">
                  <img src="@/img/services/garages/8.jpg" alt="">
                  <div class="tile-text garage-type-grid">
                    <h2 class="article-info-headline">Side Hinged Doors</h2>
                    <div class="services-grid-article-button-container">
                      <router-link to="Garage-Side-Hinged-Doors" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


