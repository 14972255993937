<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container">
        <h2>
          Quality Service – Every Time!
        </h2>
        <p>
          We are a Staffordshire based premier supplier of a range of high quality windows, gates, garage doors and industrial doors. We have highly experienced installers and service engineers that cover the whole of the UK. Be confident that when you work with Lymers you are working with one of the industries best and most experienced companies. We can assure you of quality workmanship together with a guaranteed high quality customer service that is second to none.
        </p>
      </div>          
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>