<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container garages">
        <h2>
          Lymers provides a first class service to all of our customers.
        </h2>
        <p>
          Lymers have been engaged in the supply and installation of Conservatories, Verandas andCarports for over 20 years. We pride ourselves on our exceptional knowledge and experience, as well as our dedication to customer satisfaction. So whether you are looking for a completely new conservatory/Orangery, looking to replace an existing roof or just looking for a solution for sheltered outdoor area for seating or parking a car. We have a solution for you.
        </p>
      </div>               
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>