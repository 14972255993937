<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-splash blog-real-aluminium-door"></div>
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Real Aluminium Doors</h2>
              <h4 class="blogpage-subtext">
                Lymers will always source products we feel will stand the test of time and the Real Aluminium Doors are no exception. Designed as a stand alone product or to complement our aluminium window ranges an aluminium door will add style, security and individuality to your home as well as showcasing uninterrupted views of your garden and beyond.
              </h4>
            </div>      
          </div>
        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side real-aluminium-blog">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Bi-fold Doors</h2>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            REAL Aluminium Bi-folding Doors create a true sense of space and light. The perfect addition to any home, bi-folding doors not only offer slim frame profiles and quality running mechanisms but also enhanced thermal performance. All bi-folding doors within the REAL Aluminium range have the option of either low thresholds for unimpeded, easy access, integrated cills on opening out doors, or rebated thresholds that offer improved weather resistance
                            <br/><br/>
                            Our experience tells us that aluminium is the best bi-fold choice to make and has the following benefits:
                            <ul class="text-left">
                              <li>
                                Long-lasting colour
                              </li>
                              <li>
                                High Strength
                              </li>
                              <li>
                                Ease of maintenance
                              </li>
                              <li>
                                Better functionality
                              </li>
                              <li>
                                Thermally efficient
                              </li>
                              <li>
                                Environmentally friendly
                              </li>
                            </ul>                    
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <router-link to="Contact" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>Contact us</span>
                              </button>
                            </div>
                          </router-link>
                        </div>                       
                      </div>
                      <div class="lymer-services-grid real-aluminium-door-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>
        
        <div class="blogpage-intro-container">
          <div class="blogpage-text-container">
            <h4 class="blogpage-subtext">
              Bi-fold doors are available in a number of configurations to suit the space you have and the way in which you wish to use it. Doors can open the left or right and can open in or out, the most common is out. They can be used to great effect to open your home to the outside, especially in the summer months.
              <br/><br/>
              <em>Examples of Bi-fold door styles:</em>
            </h4>

            <div class="tile-container triple-image-grid blog-real-aluminium-door">
              <div class="tile">
                <div class="tile-inner triple-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/2-pane.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner triple-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/3-pane-bifold.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner triple-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/3-pane-bifold-b.jpg" alt="">
                </div>
              </div>
            </div>

            <div class="tile-container double-image-grid blog-real-aluminium-door">
              <div class="tile">
                <div class="tile-inner double-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/4-pane.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner double-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/5-pane-bi-fold.jpg" alt="">
                </div>
              </div>
            </div>

            <div class="tile-container double-image-grid blog-real-aluminium-door">
              <div class="tile">
                <div class="tile-inner double-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/5-pane-bi-fold-a.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner double-image-grid blog-real-aluminium-door">                
                  <img src="@/img/news/11-01-24-blog/6-pane-bi-fold.jpg" alt="">
                </div>
              </div>
            </div>

          </div>      
        </div>


        <div class="blogpage-intro-container">
          <div class="blogpage-text-container">
            <div class="tile-container single-image-grid">
              <div class="tile">
                <div class="tile-inner single-image-grid">                
                  <img src="@/img/news/11-01-24-blog/real-aluminium.jpg" alt="">
                </div>
              </div>
            </div>
            <h2>Patio/Sliding Door</h2>
            <h4 class="blogpage-subtext">
              A REAL Aluminium Sliding Door adds eye-catching impact to the exterior of a home. The outward appearance focuses on stunning glass areas, highlighted by bold aluminium sections styled with clean aesthetic lines that work in harmony with the character of any home. Delivering optimum performance and tested to the highest standards, REAL Aluminium Sliding Doors guarantee reliability. The strong and thermally broken aluminium sections make large sliding doors and large glass areas possible without compromising thermal performance or weather resistance. Special sliding mechanisms enable even the largest sashes to slide effortlessly, whatever the size. Each sliding panel can be up to 2500mm high and when closed these large sliding doors form a thermally efficient glass facade, a wonderful way to flood a space with light and to enjoy beautiful views.
            </h4>            
          </div>      
        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Features:</h2>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            <ul class="text-left">
                              <li>
                                All main sections feature an extended polyamide thermal break that allows the sliding doors to achieve a U Value of 1.6 W/m2K when installed with the appropriate double-glazed sealed unit.
                              </li>
                              <li>
                                Ideal for use as residential sliding doors that maximise light and give stunning unimpeded views or as sliding commercial entrances where large opening apertures are required.
                              </li>
                              <li>
                                Polyester powder coated
                              </li>
                              <li>
                                Up to 1.3 w/m2k using a suitable triple-glazed unit
                              </li>
                              <li>
                                Multi-point locking plus individual keeps
                              </li>
                              <li>
                                99mm or 126mm double track, 165.5mm or 182.5mm triple track
                              </li>
                              <li>
                                28mm double-glazed or 36mm triple-glazed
                              </li>
                            </ul>
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid blog-real-aluminium-features-door side d50">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <WindowsParallax/>


      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
    export default {
    name: "News",
  components: {
      WindowsParallax,
      }
    };

</script>

<style lang="scss">
  

</style>
