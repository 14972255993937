<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the previous Verandas, Canopies & Carports that Lymers have designed and fitted for our customers
    </h2>
    
    <div class="services-grid garage-grid">      
      <article class="services-grid-article veranda-art one">
      </article>

      <article class="services-grid-article veranda-art two">
      </article>

      <article class="services-grid-article veranda-art three">
      </article>
      
      <article class="services-grid-article veranda-art four">
      </article>

      <article class="services-grid-article veranda-art five">
      </article>
      
      <article class="services-grid-article veranda-art six">
      </article>

      <article class="services-grid-article veranda-art seven">
      </article>
      
      <article class="services-grid-article veranda-art eight">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.veranda-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/veranda-conservatory-carports/1.jpg");
  }
  &.two {
    background-image: url("@/img/services/veranda-conservatory-carports/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/veranda-conservatory-carports/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/veranda-conservatory-carports/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/veranda-conservatory-carports/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/veranda-conservatory-carports/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/veranda-conservatory-carports/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/veranda-conservatory-carports/8.JPG");
  }
}
</style>