<template>
  <div class="lymer-services-container garages">
    <!-- Software Development Main Body Section -->
    <div class="what-we-do-container lymer-service">
      <div class="pt2 what-we-do-grid">
        <div class="services-cont">
          <div class="service-inverted what-we-do side lymer-service">
            <div class="center">
              <div class="side-by-side">
                <div class="side lymer-service-text-pad sm-90 d50 l60">
                  <h2 class="lymer-service-headline service-headline">Electrical services you can trust</h2>
                  <ul class="what-we-do-body-text lymer-service">
                    <p class="lymer-service-body-text">
                      All of our electricians are trained to the highest industry standards and are accredited to ensure we deliver the safest electrical services possible.
                      <br/> <br/>
                      If you have electrical projects that require a safety accredited electrician to provide you with peace of mind knowing that your electrical system is safe and secure, speak to one of our sales team today.                                      
                    </p>              
                  </ul>
                  <div class="mt-16 lymer-service-button-container ">                    
                    <router-link to="Contact" class="services-grid-article-button">
                      <!-- <button class="client-button">Read More</button> -->
                      <div class="client-button-container lymer-service-button">
                        <button class="client-button">
                          <svg viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                          </svg>
                          <span>Contact Us</span>
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="lymer-services-grid electrical-services-bg side sm-90 d50 l40">
                </div>
              </div>              

            </div>
          </div>              
        </div>
      </div> 
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

  
</style>