<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <div class="services-grid garage-grid">
      <article class="services-grid-article windows-art one">
      </article>

      <article class="services-grid-article windows-art two">
      </article>

      <article class="services-grid-article windows-art three">
      </article>
      
      <article class="services-grid-article windows-art four">
      </article>

      <article class="services-grid-article windows-art five">
      </article>
      
      <article class="services-grid-article windows-art six">
      </article>

      <article class="services-grid-article windows-art seven">
      </article>
      
      <article class="services-grid-article windows-art eight">
      </article>

      <article class="services-grid-article windows-art nine">
      </article>
      
      <article class="services-grid-article windows-art ten">
      </article>

      <article class="services-grid-article windows-art eleven">
      </article>
      
      <article class="services-grid-article windows-art twelve">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.windows-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/windows/1.png");
  }
  &.two {
    background-image: url("@/img/services/windows/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/windows/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/windows/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/windows/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/windows/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/windows/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/windows/8.jpg");
  }
  &.nine {
    background-image: url("@/img/services/windows/9.jpg");
  }
  &.ten {
    background-image: url("@/img/services/windows/10.JPG");
  }
  &.eleven {
    background-image: url("@/img/services/windows/11.JPG");
  }
  &.twelve {
    background-image: url("@/img/services/windows/12.jpg");
  }
}
</style>