<template>
        
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the previous gates and railings Lymers have designed and fitted for our customers
    </h2>
    <div class="services-grid garage-grid">
      <article class="services-grid-article gates-art one">
      </article>

      <article class="services-grid-article gates-art two">
      </article>

      <article class="services-grid-article gates-art three">
      </article>
      
      <article class="services-grid-article gates-art four">
      </article>

      <article class="services-grid-article gates-art five">
      </article>
      
      <article class="services-grid-article gates-art six">
      </article>

      <article class="services-grid-article gates-art seven">
      </article>
      
      <article class="services-grid-article gates-art eight">
      </article>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.gates-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/gates/1.jpg");
  }
  &.two {
    background-image: url("@/img/services/gates/2.jpg");
  }
  &.three {
    background-image: url("@/img/services/gates/3.jpg");
  }
  &.four {
    background-image: url("@/img/services/gates/4.jpg");
  }
  &.five {
    background-image: url("@/img/services/gates/5.jpg");
  }
  &.six {
    background-image: url("@/img/services/gates/6.jpg");
  }
  &.seven {
    background-image: url("@/img/services/gates/7.jpg");
  }
  &.eight {
    background-image: url("@/img/services/gates/8.jpg");
  }
}
</style>