<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/news/11-01-24-blog/icotherm-hero.jpg" alt="">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Icotherm Roof</h2>
              <h4 class="blogpage-subtext">
                If you have a conservatory with a glass or polycarbonate roof you will already know that it is cold in the winter and, if it is in direct sunlight, too hot in the summer. This means you only get to use the space for around half the year.
                <br/><br/>
                Replacing your existing conservatory roof with an Icotherm roof will completely transform your conservatory into a room you can use all year round.  Saving you money on energy bills and increasing the value of your property. If you have started to think you are outgrowing your home, having an Icotherm roof can save you the stress and expense of having to move.                
              </h4>
            </div>      
          </div>

        </div>

        <!-- Conservatory roof replacement -->
        <div class="lymer-services-container compdoor">          
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side icotherm-roof">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Conservatory roof replacement with 0.15 U-Value insulation</h2>
                        <ul class="what-we-do-body-text lymer-service">                          
                          <p class="lymer-service-body-text">
                            Icotherm’s range of  conservatory roof replacement solutions are designed using premium C24 timber. The roofs are highly insulated to transform your conservatory into a space that can be used all year-round.
                            <br/><br/>
                            Icotherm roofs can be made to match or redesign any existing conservatory roof as well as the ideal roof for a new conservatory.
                            <br/><br/>
                            Our conservatory roof replacement solutions are building control compliant, providing you with the reassurance you need.
                            <br/><br/>
                            The roof tiles are available in a range of finishes:                  
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <router-link to="Contact" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>Contact Us</span>
                              </button>
                            </div>
                          </router-link>
                        </div>                      
                      </div>
                      <div class="lymer-services-grid icotherm-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <VerandaTypeGrid/>

        <div class="blogpage-intro-container mb-32">
          <div class="blogpage-text-container">
            <div class="tile-container single-image-grid">
              <div class="tile">
                <div class="tile-inner single-image-grid icoroof">                
                  <img src="@/img/news/11-01-24-blog/ico-sub-hero.jpg" alt="">
                </div>
              </div>
            </div>
            <h2>Suitable for any Icoroof with squared panels</h2>
            <ul class="what-we-do-body-text lymer-service text-left icoroof-text">
              <p class="lymer-service-body-text text-left ">
                A contemporary version of our time tested Icoroof, incorporating full height glass panels for a modern appearance, bringing light to your new room.                
              </p>
              <li>
                Ventilated
              </li>
              <li>
                Unique inline design for a pleasing appearance
              </li>
              <li>
                Aluminium top caps
              </li>
              <li>
                Single, double or triple pods available
              </li>
              <li>
                Glass panel maximum width: 800mm (less might be visible)
              </li>            
            </ul>
            <br/>
            <h2>FAQ</h2>
            <h4 class="text-left"><em>Will the roof be too heavy for my existing conservatory?</em></h4>
            <p class="lymer-service-body-text text-left">
              No. An Icotherm tiled roof conservatory is only marginally heavier than a glass roof. As per LABC guidelines (April 2016), if the structure shows no signs of distress, the existing foundations are likely to be adequate to carry the small additional loadings. Existing glazing and door support must also be reinforced.                
            </p>
            <br/>
            <h4 class="text-left"><em>Will I be able to use my room all year round?</em></h4>
            <p class="lymer-service-body-text text-left">
              Yes. The addition of an Icotherm tiled roof can make your room up to 90 percent warmer in winter and 70 percent cooler in summer. Additional benefits include reduced noise pollution and no solar glare.               
            </p>
            <br/>
            <h4 class="text-left"><em>Will my Icotherm roof be strong enough to stand on whilst I clean my windows?</em></h4>
            <p class="lymer-service-body-text text-left">
              Recent testing proved a 6.4m x 6.4m Icotherm roof could carry the weight of four modern Mini cars without showing any signs of movement. When standing on the roof take care of where you step or apply pressure to ensure tiles do not get damaged.           
            </p>           
          </div>      
        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side conservblog">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <div class="tile-container single-image-grid">
                          <div class="tile">
                            <div class="tile-inner single-image-grid icospan">                
                              <img src="@/img/news/11-01-24-blog/icospan-sub-hero.png" alt="">
                            </div>
                          </div>
                        </div>


                        <h2>A modern take for added natural light</h2>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            The perfect choice if you are looking to build an orangery or convert your existing conservatory Lymers can help you make the right decision to achieve what you are looking for.
                            <br/><br/>
                            Using a warm roof type construction, this solution is probably the most efficient flat roof on the market thanks to our extensive R&D work. With a U-Value: 0.17 the Icospan roof is:
                            <ul class="text-left">
                              <li>
                                Eco friendly
                              </li>
                              <li>
                                Thermally efficient
                              </li>
                              <li>
                                Building control compliant
                              </li>                              
                            </ul>
                            The Icoroof uses fully recyclable 155mm EPS insulation & FSC Certified timber.
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid icospan-bg side d50">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <WindowsParallax/>

      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
  import VerandaTypeGrid from "@/components/VerandaTypeGrid.vue";
  export default {
    name: "News",
    components: {
      VerandaTypeGrid,
      WindowsParallax,
    }
  };

</script>

<style lang="scss">
</style>
