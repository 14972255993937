<template>
 <div class="welcome">
    <div class="">
      <div class="welcome-intro-container">
        <h1 class="fancy-font">
          Quality Service – Every Time!
        </h1>
        <p>
          We are a Staffordshire based supplier of a range of high quality windows, doors, gates, garage doors and industrial doors. We have highly experienced installers and service engineers that cover the whole of the UK. Be confident that when you work with Lymers you are working with one of the industries best and most experienced companies. We can assure you of quality workmanship together with a guaranteed high quality customer service that is second to none.
        </p>
      </div>
      <div class="welcome-box-container">        
        <div class="wave-text-container">
          <h3>
            Lymers is a family run business that has been providing quality workmanship and exceptional service since 2003.
          </h3>       
        </div>         
      </div>           
    </div>    
  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">

</style>