<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/news/hero/upvc-door-blog.jpg" alt="">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>UPVC Residential Doors</h2>
              <h4 class="blogpage-subtext">
                UPVC doors have been the popular choice for many years. Residential, french doors are available in a wide range of styles and finishes and can be manufactured to open inward or outward.
              </h4>
            </div>      
          </div>

        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side blog-upvc-door">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            The front door is the most used UPVC product on any home and the first thing people will notice when visiting. Our secure and stylish UPVC entrance doors are available in a wide range of designs and finishes to fully suit your home.
                            <br/><br/>
                            With a choice of full panel, half panel and side panel designs, you have the flexibility to choose the right design for the levels of privacy and light you require. Show your personal style with a selection of solid colour or woodgrain finishes. With many designs to choose from, Lymers  can help you select the right colour, style and accessories for your home.
                            <br/><br/>
                            Why Lymers choose Rehau UPVC doors:
                            <ul class="text-left">
                              <li>
                                70mm frame depth front to back
                              </li>
                              <li>
                                Internally beaded
                              </li>
                              <li>
                                Colour suited hardware
                              </li>
                              <li>
                                Low threshold options, 15mm aluminium for wheelchair access
                              </li>
                              <li>
                                Fully adjustable 3 directional suited door flag hinges 
                              </li>
                              <li>
                                Keyed alike cylinders to all french doors
                              </li>
                            </ul>                    
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <router-link to="Contact" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>Contact us</span>
                              </button>
                            </div>
                          </router-link>
                        </div>                       
                      </div>
                      <div class="lymer-services-grid upvc-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <!-- Colour Options Tiles -->
        <div class=""> 
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Colour Options</h2>           
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container colour-options-grid blog-type-grid three">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/white.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/golden-oak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Golden Oak and Golden Oak on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/rosewood.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Rosewood and Rosewood on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/ChartwellGreeny.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Chartwell Greeny on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/ironoak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Irish Oak Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/whitewoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/creamwoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Cream Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/blackonwhite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Black on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/anthracite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Anthracite Grey on White
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>
      
        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side blog-upvc-door-bay-section">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Create the look and style of your door</h2>
                        <h4>UPVC doors are glazed with either glass (clear or patterned) or a full/half panel.</h4>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            Our door panels are made up of two UPVC skins pressure bonded to a core material of E.H.D.F.R.A (Extra High-Density Fire Retardant Additive) polystyrene using a specially formulated adhesive that’s both heat and moisture-resistant.
                            <br/><br/>
                            Capable of providing an energy rating of A+ due to UPVC being a poor conductor of heat which means it keeps more heat inside instead of letting it out. The main benefit of such excellent thermal performance is that you can enjoy a cosier home for less, as you won’t have to spend so much on your heating bills, which is essential with all the rising energy bills. This also contributes to the planet as you are using fewer fossil fuels, you’re being more eco-friendly!                
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <a href="https://www.vistapanels.co.uk/wp-content/uploads/2021/11/Vista-Panel-Brochure-2021.pdf" target="_blank" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>View Catalogue</span>
                              </button>
                            </div>
                          </a>
                        </div>   
                      </div>
                      <div class="lymer-services-grid upvc-catalogue-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <WindowsParallax/>


      </div>
    </main>
  </body>
</template>

<script>
  import WindowsParallax from "@/components/WindowsParallax.vue";
  export default {
  name: "News",
  components: {
    WindowsParallax,
    }
  };

</script>

<style lang="scss">
</style>
