<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <div class="hero-splash blog-comp-door"></div>
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>COMP DOOR</h2>
              <h4 class="blogpage-subtext">
                
              </h4>
            </div>      
          </div>

        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side blog-comp-door">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            When you buy a Comp Door composite entrance door, you’ll instantly benefit from years of expert engineering combined to create a premium door with market-leading performance at a competitive price. We’ve partnered with industry-leading innovators to bring together the very best the market has to offer. Every detail has been carefully considered and the finest components selected to make the UK’s best quality composite entrance door.
                            <br/><br/>
                            Why Lymers choose Comp doors:
                            <ul class="text-left">
                              <li>
                                Over 250 colour combinations inside & out
                              </li>
                              <li>
                                48mm solid timber core
                              </li>
                              <li>
                                Outstanding 1.4 W/m²K u-Value
                              </li>
                              <li>
                                Highly advanced secure locking system
                              </li>
                              <li>
                                Chamfered or sculptured frames
                              </li>
                              <li>
                                All locks compliant PAS24: 2016
                              </li>
                              <li>
                                ABS security cylinder as standard
                              </li>
                            </ul>                    
                          </p>              
                        </ul>
                        <div class="mt-16 lymer-service-button-container ">                    
                          <router-link to="Contact" class="services-grid-article-button">
                            <!-- <button class="client-button">Read More</button> -->
                            <div class="client-button-container lymer-service-button">
                              <button class="client-button">
                                <svg viewBox="0 0 180 60" class="border">
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                                  <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                                </svg>
                                <span>Contact us</span>
                              </button>
                            </div>
                          </router-link>
                        </div>                        
                      </div>
                      <div class="lymer-services-grid comp-door-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <DoorsDesignYourOwn/>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side blog-why-comp-door">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Why choose Comp Door</h2>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            At the heart of every standard Comp Door is a premium cross laminated Albasia Falcata timber core, designed to be thermally efficient and offer maximum security. The timber core is then wrapped in revolutionary, high-performance CoolSkin technology, engineered to withstand extreme temperature changes and maintain optimum impact performance, with a colour protective layer so that it won’t get worn away or chipped.
                            <br/><br/>
                            Comp Door is beautiful and built to last, delivering market-leading U-values for high energy efficiency. The door has an industry-first TriSeal frame, offering better weatherproofing and reducing chance of drafts for a cosy home that is comfortable, safe and secure.
                            <br/><br/>
                            After all, why compromise on safety when your Comp Door is fitted with the renowned ABS anti-snap diamond grade cylinder, as standard, for ultimate defence. Thermal movement can be part of any composite door product. Our unique core combined with our Auto Slam Shut lock as standard, is designed to minimise this.
                            <br/><br/>
                            Also, we incorporate a technically advanced InvisiEdge system, giving a seamless colour edge to the door faces. This maintains perfect aesthetics whilst ensuring up to 100% moisture resistance. This helps to stop any unwanted water penetration and protects your timber core, while staying discreetly concealed behind your beautiful Comp Door exterior.
                          </p>              
                        </ul>
                          
                      </div>
                      <div class="lymer-services-grid why-comp-door-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>
        
        <div class="blogpage-intro-container">
          <div class="blogpage-text-container">
            <h2>Tried, Tested & Some!</h2>
            <h4 class="blogpage-subtext">
              ABS three star rated locks are approved by the Police security initiative “Secured by Design” and are tested by the Master Locksmith Association, passing with the Sold Secure Diamond grade – recognised as the highest security standard UK locks can conform to!
              <br/><br/>
              <em>ABS is stamped with the insurance preferred British Kitemark – KM 586153</em>
            </h4>

            <div class="tile-container triple-image-grid">
              <div class="tile">
                <div class="tile-inner triple-image-grid">                
                  <img src="@/img/news/10-01-24-blog/abs-lock.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner triple-image-grid">                
                  <img src="@/img/news/10-01-24-blog/sold-secure.jpg" alt="">
                </div>
              </div>
              <div class="tile">
                <div class="tile-inner triple-image-grid">                
                  <img src="@/img/news/10-01-24-blog/kitemark.jpg" alt="">
                </div>
              </div>
            </div>
          </div>      
        </div>

        <div class="">
          <section class="image-text floors">
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container double-image-grid">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner double-image-grid">                
                      <img src="@/img/news/10-01-24-blog/mzp.jpg" alt="">
                      <div class="tile-text double-image-grid">
                        <h2 class="article-info-headline">Multi-Zone Protection</h2>
                        <p class="article-info-body-text double-image-grid">
                          ABS locks feature advanced technology to combat against lock snapping, picking, drilling and bumping.
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner double-image-grid">
                      <img src="@/img/news/10-01-24-blog/asp.jpg" alt="">
                      <div class="tile-text double-image-grid">
                        <h2 class="article-info-headline">Contemporary frame - Bevel sash</h2>
                        <p class="article-info-body-text double-image-grid">
                          When attacked a hidden lock within the hardened Molybdenum cam fires into action to create a barrier that would-be intruders can’t get past.
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <div class="blogpage-intro-container mt-40">
          <div class="blogpage-text-container">
            <div class="tile-container single-image-grid">
              <div class="tile">
                <div class="tile-inner single-image-grid">                
                  <img src="@/img/news/10-01-24-blog/compdoor-anti-snap.jpg" alt="">
                </div>
              </div>
            </div>
            <h2>Some locks have anti-snap features on the outside and inside part, Why?</h2>
            <h4 class="blogpage-subtext">
              There are lots of different sizes of lock to suit lots of different thicknesses of doors… Putting anti-snap features on both sides makes it cheaper for the company holding stock as they don’t have to hold as many product lines. This could mean that you pay for features you don’t need on the inside part of the lock.
              <br/><br/>
              ABS has all snap security features on the outside only – to ensure the inside section does not break away when attacked.
              <br/><br/>
              If ABS has been attacked and snapped from the outside the homeowner can still open and close the door safely and securely from the inside.
              <br/><br/>
              Your ABS lock includes a unique key code system which you can link to you. You can do this online at the Key Zone or over the phone 24/7 every day of the year.
            </h4>
            
          </div>      
        </div>

        <div class="blogpage-intro-container">
          <div class="blogpage-text-container">
            <h2>How long do Composite Doors last?</h2>
            <h4 class="blogpage-subtext">
              Composite doors are made from a combination of materials, typically including a solid timber core, a fiberglass or uPVC outer skin, and a layer of insulation. The quality of these materials plays a significant role in determining the lifespan of the door.
              <ol type="a">
                <li>
                  <em>Solid Timber Core:</em> The solid timber core provides strength, stability, and resistance to warping or twisting over time. High-quality composite doors use timber cores that are specially treated to resist moisture and decay, ensuring long-term durability.
                </li>
                <li>
                  <em>Outer Skin:</em> The outer skin, made of fiberglass or uPVC, protects the door from external elements such as sunlight, rain, and temperature fluctuations. These materials are chosen for their durability, weather resistance, and low maintenance requirements.
                </li>
                <li>
                  <em>Assembly:</em> The way the various components of the door are bonded and assembled also affects its longevity. Quality composite doors like those here at Comp Door, are manufactured using advanced techniques and adhesives to ensure a strong and lasting bond between the materials.
                </li>
              </ol>
            </h4>
            
          </div>      
        </div>

        <WindowsParallax/>

      </div>
    </main>
  </body>
</template>

<script>
  import DoorsDesignYourOwn from '@/components/DoorsDesignYourOwn.vue'
  import WindowsParallax from "@/components/WindowsParallax.vue";
  export default {
  name: "News",
  components: {
    WindowsParallax,
    DoorsDesignYourOwn,
    }
  };

</script>

<style lang="scss">

  .lymer-service {

    &.blog {
      padding-top: 0vw;
      padding-bottom: 2vw;
    }    
  }
</style>
