<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="home"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/home/hero.jpg" alt="Lymers garage and windows client work">
        </section>
        <Welcome/>
        <Introduction/>        
        <Parallax/>
        <LatestNews/>
        <Reviews/>       
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>
<script>
import Welcome from '@/components/Welcome.vue'
import Introduction from '@/components/Introduction.vue'
import Parallax from '@/components/Parallax.vue'
import LatestNews from '@/components/LatestNews.vue'
import Reviews from "@/components/Reviews.vue";

export default{
  components:{
    Welcome,
    Introduction,
    Parallax,
    LatestNews,
    Reviews
  }
};

</script>

<style lang="scss">

video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}

</style>