<template>
  <h2 class="reviews-header">
    <b>Our Reviews</b> 
  </h2>
  <div class="wrapper">
    <div class="item01"><img src="@/img/testimonials/Jude1.jpg" alt="">
      <p>
        “Fantastic job. The lads worked tirelessly all day even though they were under the weather. They cleaned up everywhere and left no mess. I would recommend 100%, well done and get well soon guys.”
        <br/>
        <b>Jude Brown</b>
      </p>
      
    </div>
    <div class="item02"><img src="@/img/testimonials/Judith.jpg" alt="">
      <div class="paragraph">
        <p>
          “Before and after. Brilliant work and very happy with it. Came within a week of giving me the quote, cheapest of 4 quotes I received. Highly recommended.”
          <br/>
        <b>Judith Davison</b>
            
        </p>
        
      </div>
    </div>
    <div class="item03">
      <p>
        “Great prices and an excellent job all round. Fitters were very professional and took pride in their work - very tidy. A credit to the company - would highly recommend.”
        <br/>
        <b>Richard Griffiths</b>
          
      </p>
      
    </div>
    <div class="item04"><img src="@/img/testimonials/Peter.png" alt="">
      <p>
        “Great conversion carried out by Lymers.  This job included removal of dividing walls, fitting of RSJ x 2, 1 automatic double sized door and 1 single. Finished off with new facias and guttering. The price was right and they did a seamless job with no hiccups whatsoever.  Definitely recommend Lymers.  More than just garage door specialists.”
        <br/>
        <b>Peter Sestic</b>
      </p>
      
    </div>    
  </div>
</template>

<script>
export default{
    mounted(){

    }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';

.reviews-header {
  color: $primary-colour;
  width: 50vw;
  margin: 4rem auto 2rem;
  text-align: center;

  @include breakpoint-desktop {
  }

  @include breakpoint-small-mobile {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-mobile {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-tablet {
    margin: 1rem auto 1rem;
  }

  @include breakpoint-large-tablet {
    margin: 1rem auto 1rem;
  }
}

.wrapper{
  width: 100%;
  max-width: 80vw;
  height: fit-content;
  margin: 0 auto;
  padding: 0 2%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 20rem repeat(4, minmax(2rem, 15rem));
  grid-gap: 1rem;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  @include breakpoint-above-large-tablet {
    grid-template-rows: unset;
    margin-bottom: 4rem;
  }
}

.item01{
  grid-column: 1/2;
  grid-row: 1/4;
  display: flex;
  vertical-align: middle;
  justify-content: flex-start!important;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: inherit;
    @include breakpoint-large-tablet {
      display: flex;
      flex-direction: column;
    }
  }
}

.item02 {
  grid-column: 2/5;
  grid-row: 1/2;
  display: flex;
  flex-direction: row;
  font-size: inherit;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: row;
  }

  p {
    font-size: inherit;
    @include breakpoint-large-tablet {
      display: flex;
      flex-direction: column;
    }
  }

  .paragraph {
    display: flex;
    flex-direction: column;
  }

  img {
    @include height-fit-content;

    @include breakpoint-large-tablet {
      width: 50%;
    }

    @include breakpoint-above-large-tablet {
      width: auto;
      height: 21rem;
    }
  }  
}

.item03 {
  grid-column: 1/2;
  grid-row: 4/4;
  display: flex;
  flex-direction: column;
  font-size: inherit;  

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 1.1rem;

    @include breakpoint-laptop {
      font-size: 1.1vw;
    }
  }
}

.item04{
  grid-column: 2/5;
  grid-row: 2/5;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-start!important;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: inherit;

  @include breakpoint-small-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-mobile {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-tablet {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint-large-tablet {
    display: flex;
    flex-direction: column;
  }

  p {
    padding: 3rem 4rem !important;
    font-size: 1.5rem;

    @include breakpoint-desktop {
      font-size: 1.2vw;  
    }

    @include breakpoint-small-mobile {
      font-size: 1.1rem;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-mobile {
      font-size: 1.1rem;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-tablet {
      font-size: inherit;
      padding: 1rem 1rem !important;
    }

    @include breakpoint-large-tablet {
      font-size: inherit;
      padding: 1rem 1rem !important;
    }
  }
}

.heading{
  grid-column: 1/5;
  @extend %center;
  font-size: 2rem;  
  text-transform: uppercase;
  background-color: #FF12A9;
  font-style: oblique;
}


[class^="item"]{
  overflow: hidden;
  border-radius: 3px;
  @extend %center;
  position: relative;
  background-color: $primary-colour;

  h1,h2,h3,h4,h5,h6 {
    margin-top: 0.5rem;
    padding: 0rem 1rem;
  }

  p {
    padding: 1rem;
  }
}

[class^="item"]::before{ 
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  @extend %center;
  color: #FFF;
  background-color: $primary-colour;
}


%center{
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.meta{
  transform: translateY(100%);
  opacity: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  bottom: 0;
  color: #FFF;
  background: linear-gradient(to top, rgba(22,22,22,0.65) 30%,rgba(22,22,22,0.64) 31%,rgba(0,0,0,0.29) 69%,rgba(0,0,0,0) 100%);
  transition: 1s;
}

[class^="item"]:hover .meta{
  transform: unset;
  opacity: 1;
}

.meta .cta-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  @extend %center;
}

.meta .cta-list .cta-item{
  flex: 1;
  transition: .2s;
}

.meta .cta-list .cta-item:hover{
  transform: scale(1.2) rotate(-15deg);
  cursor: pointer;
}

.quote{
  font-size: 2em;
  color: #efefef;
  font-style: italic;
  transition: .3s;
}

.quote::after{
  content: "~ "attr(cite);
  font-size: .5em;
  display: block;
  padding-top: 5px;
}

@media (max-width: 960px){
  .heading{
    font-size: 1rem;  
    grid-column: span 2;
  }
  
  .wrapper{
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }
  
  [class^="item"]{
    grid-column: unset;
    grid-row: unset;
  }
}

@media (max-width: 476px){
  .heading{
    grid-column: span 1;
  }
  
  .wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
}

  
  @keyframes spin {
    from{
      transform: translate(-50%,-50%) rotate(0deg)
    }
    to{
      transform: translate(-50%,-50%) rotate(1440deg);
      opacity: 0;
      content: unset;
    }
  }

@keyframes blink{
  from{}
  to{
    opacity: 0;
    content: unset;
  }
}
</style>