<template>
  <div class="news-page">

    <div class="">      
      <!-- Project Navigation Tile -->
      <section class="image-text floors">
        <Grid class="pt2" center middle>
          <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
            <div class="tile-container windows-type-grid">

              <!-- New tile article goes here -->
              <div class="tile">
                <div class="tile-inner windows-type-grid">                
                  <img src="@/img/services/windows/TypeGrid/standard_casement.jpg" alt="">
                  <div class="tile-text windows-type-grid">
                    <h2 class="article-info-headline">Standard Casement</h2>
                    <p class="article-info-body-text windows-type-grid">
                      Casement windows are available in a wide range of styles and colours and also provide maximum energy efficiency and performance whilst guaranteeing low maintenance due to the unique compound with a smooth gloss finish.
                    </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Rehau-Windows" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner windows-type-grid">
                  <img src="@/img/services/windows/TypeGrid/flush_fit.jpg" alt="">
                  <div class="tile-text windows-type-grid">
                    <h2 class="article-info-headline">Flush Fit</h2>
                    <p class="article-info-body-text windows-type-grid">
                      The 'A' Energy rated Flush Fit features clean lines and a true finish in order to ensure minimalist impact on the property's exterior. Available in both chamfered and sculptured frame options, Rio can suit any installation.
                    </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Flush-Fit-Windows" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>

              <div class="tile">
                <div class="tile-inner windows-type-grid">
                  <img src="@/img/services/windows/TypeGrid/tilt_and_turn.jpg" alt="">
                  <div class="tile-text windows-type-grid">
                    <h2 class="article-info-headline">Tilt & Turn</h2>
                    <p class="article-info-body-text windows-type-grid">
                      Tilt & Turn windows allow maximum opening widths with the option to be tilted inwards from the top for normal ventilation or can be fully opened from the side for full ventilation and ease of maintenance.
                    </p>
                    <div class="services-grid-article-button-container">
                      <router-link to="Blog-Tilt-Turn" class="services-grid-article-button">
                        <div class="client-button-container">
                          <button class="client-button">
                            <svg viewBox="0 0 180 60" class="border">
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                              <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                            </svg>
                            <span>Read More</span>
                          </button>
                        </div>
                      </router-link>
                    </div>
                  </div> 
                </div>
              </div>  

              <div class="tile">
                <div class="tile-inner windows-type-grid">
                  <img src="@/img/services/windows/TypeGrid/special_frame.jpg" alt="">
                  <div class="tile-text windows-type-grid">
                    <h2 class="article-info-headline">Shaped Frame</h2>
                    <p class="article-info-body-text windows-type-grid">
                      From circles to gothic arches, triangles to gable frames, UPVC can be made to suit most openings and are available in multiple colour choices. Shaped frames can create a modern look or keep a traditional style.
                    </p>
                    <div class="services-grid-article-button-container">
                    </div>
                  </div> 
                </div>
              </div>
              
            </div>
          </div>
        </Grid>
      </section>
    </div>
  </div>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
  @import '../styles/base/_mixins.scss';
  @import '../styles/base/_variables.scss';

</style>


