<template>   
  <!-- Services Boxes -->
  <div id="services" class="secondary-back client-services home">
    <h2 class="text-center">
      See some of the previous electrical works we have provided for our customers
    </h2>
    <div class="image-mosaic mt-32">
      <div class="card card-tall card-wide electrical-art one"></div>
      <div class="card card-tall card-wide electrical-art two"></div>
      <div class="card card-tall card-wide electrical-art three"></div>
      <div class="card card-tall card-wide electrical-art four"></div>
      <div class="card card-tall card-wide electrical-art five"></div>
      <div class="card card-tall card-wide electrical-art six"></div>
    </div>
  </div>

</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import '../styles/base/_variables.scss';
.secondary-back {
  background-color: $primary-colour;
}

.electrical-art {
  border-radius: 0px;
  background-size: cover;
  background-position: center;

  &.one {
    background-image: url("@/img/services/electrical/6.jpg");
  }
  &.two {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.three {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.four {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.five {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.six {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.seven {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
  &.eight {
    background-image: url("@/img/branding/lymers-placeholder.jpg");
  }
}
</style>