<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/news/hero/ryterna-door-blog.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Ryterna RD 80</h2>
              <h4 class="blogpage-subtext">The Ryterna RD80 is the perfect door choice if you have chosen a Ryterna garage door and would like your front to match. These doors offer high quality with some great design features.
              </h4>
            </div>      
          </div>

          <div class="">      
            <!-- Project Navigation Tile -->
            <section class="image-text floors">
              <Grid class="pt2" center middle>
                <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                  <div class="tile-container features-grid ryterna-doors-blog-type-grid">

                    <!-- New tile article goes here -->
                    <div class="tile">
                      <div class="tile-inner ryterna-doors-type-tile">                
                        <img src="@/img/services/doors/ryterna-type-grid/ryterna-handle.jpg" alt="">
                        <div class="tile-text ryterna-doors-type-tile-text">
                          <h2 class="article-info-headline">Inlaid pull handle</h2>
                          <p class="article-info-body-text">
                            <ul>
                              <li>
                                  Inlaid handle made of the specially designed aluminium profile gives the door an extra look. Optional replaceable LED backlight with photo-sensors makes the door handle shining during twilight. Rear handle panel can be powder coated in a custom colour, wood image or have corten cladding.
                              </li>
                            </ul>
                          </p>
                        </div> 
                      </div>
                    </div>

                    <div class="tile">
                      <div class="tile-inner ryterna-doors-type-tile">
                        <img src="@/img/services/doors/ryterna-type-grid/ryterna-hinge.jpg" alt="">
                        <div class="tile-text ryterna-doors-type-tile-text">
                          <h2 class="article-info-headline">Concealed Hinges</h2>
                          <p class="article-info-body-text">
                            <ul>
                              <li>
                                Hinges integrated into the leaf and jamb, so the door looks nice from both sides. Furthermore, we managed to keep the continuous perimeter rubber sealing without diminishing insulation.
                              </li>
                            </ul>
                          </p>
                        </div> 
                      </div>
                    </div>

                    <div class="tile">
                      <div class="tile-inner ryterna-doors-type-tile">                
                        <img src="@/img/services/doors/ryterna-type-grid/ryterna-double.jpg" alt="">
                        <div class="tile-text ryterna-doors-type-tile-text">
                          <h2 class="article-info-headline">Double front doors</h2>
                          <p class="article-info-body-text">
                            <ul>
                              <li>
                                  Perfectly designed double doors will create a drawing point for the viewer and highlight all other architectural features. Installing double front doors you will get larger access points and create a particular architectural style.
                              </li>
                            </ul>
                          </p>
                        </div> 
                      </div>
                    </div>

                    <div class="tile">
                      <div class="tile-inner ryterna-doors-type-tile">
                        <img src="@/img/services/doors/ryterna-type-grid/ryterna-insulation.jpg" alt="">
                        <div class="tile-text ryterna-doors-type-tile-text">
                          <h2 class="article-info-headline">Perfect insulation</h2>
                          <p class="article-info-body-text">
                            <ul>
                              <li>
                                  U values: RD80 – 0.68 W/m²K*, RD100 – 0.65 W/m²K*. Insulated aluminum frame and leaf, freon free PU foam.
                              </li>
                            </ul>
                          </p>
                        </div> 
                      </div>
                    </div>
                    
                  </div>
                </div>
              </Grid>
            </section>
          </div>

          <WindowsParallax/>
        </div>
      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
    export default {
    name: "News",
    components: {
    WindowsParallax,
  }
    };

</script>

<style lang="scss">

</style>
