<template>
     <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="News"
    itemscope
    itemtype="http://schema.org/WebPage"
    > 
    <main class="main-wrap">
      <div class="content-wrap">
        
        <section class="hero">
          <img class="" src="@/img/news/hero/flush-fit-blog-hero.jpg" alt="">
        </section>

        <div class="blogpage">
          <div class="blogpage-intro-container">
            <div class="blogpage-text-container">
              <h2>Rio Flush Fit Windows</h2>
              <h4 class="blogpage-subtext">We are often asked by customers if there is a way they can replace their old draft timber windows without losing the character of their home. Lymers always recommend the Rio Flush, which perfectly replicates the look of a timber window without all the maintenance and upkeep.
              </h4>
            </div>      
          </div>
        </div>

        <div class="">          
          <!-- Project Navigation Tile -->
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Features</h2>
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container features-grid blog-type-grid three blog-flush-fit-windows">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/09-01-23-blog/flush-fit.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          When it comes to design features and sophisticated styling, the Rio flush fit window is in a class of its own.
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/09-01-23-blog/flush-fit2.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          As with all REHAU windows, safety, security and longevity of your windows is paramount. Your Rio flush fit windows benefit from enhanced noise reduction, energy efficiency and are easy to maintain, keeping your windows looking good throughout their lifetime.
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/09-01-23-blog/flush-fit3.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          The windows have been specifically designed so that the opening sash of your window sits flush with the outer frame, giving a unique sleek and seamless appearance. Not only does the Rio flush fit window authentically replicate the features of a traditional timber window, it also complements more modern homes perfectly with clear lines and minimalist appearance.
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <div class="lymer-services-container garages w80-blog-cont">
          <!-- Software Development Main Body Section -->
          <div class="what-we-do-container lymer-service">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="side-by-side features-grid">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2 class="lymer-service-headline service-headline">Example Installation of Flush Windows</h2>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            We have replaced the timber windows and doors with Rehau Flush Fit UPVC windows, new aluminium patio door and the front door has been replaced with a Comp Door.                            
                            <br/><br/>
                            The finished look shows what has been achieved and, we think you would agree, has achieved what the customer wanted.                    
                          </p>              
                        </ul>
                        
                      </div>
                      <div class="lymer-services-grid flush-fit-blog-services-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>
        </div>

        <div class="">      
          <!-- Project Navigation Tile -->
          <section class="image-text floors">
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container features-grid blog-type-grid three">

                  <!-- New tile article goes here -->
                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/09-01-24-flush-fit-blog/1.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          The image shows the original front of the property. Although the customer wanted to bring their home up to date they also wanted to maintain the traditional look of a timber window. Lymers feels the Rehau Rio Flush Fit was the perfect option for this property. 
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/09-01-24-flush-fit-blog/2.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Those of you with a keen eye would have noticed there are some other changes between the before and after images. The customer wanted to add ‘stone cills’ to the property and also wanted all fascia, soffit and guttering replaced. Lymers have skilled engineers and we were able to carry this work out at the same time as installing the windows.
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/09-01-24-flush-fit-blog/3.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          All the doors were also replaced and these have also really helped with transforming the look of the property whether it’s the Comp Door (Link to Comp Door page) to the front or the REAL aluminium patio (LINK to aluminium page) which was chosen for its thin profile and square edges reflecting the look of the windows
                        </p>
                      </div> 
                    </div>
                  </div>

                </div>
              </div>
            </Grid>
          </section>
        </div>

        <!-- Colour Tiles Options Grid -->
        <div class="">
          <section class="image-text floors">
            <h2 class="text-center" style="color:#104086; margin-bottom: 0.5rem;">Colour Options</h2>           
            <Grid class="pt2" center middle>
              <div :below-laptop-width="100" :column-width="100" below-laptop-text-center>            
                <div class="tile-container colour-options-grid blog-type-grid three">

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/white.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/golden-oak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Golden Oak and Golden Oak on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/rosewood.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Rosewood and Rosewood on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/ChartwellGreeny.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Chartwell Greeny on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">                
                      <img src="@/img/news/11-12-23-blog/ironoak.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Irish Oak Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/whitewoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          White Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/creamwoodgrain.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Cream Woodgrain Both Sides
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/blackonwhite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Black on White
                        </p>
                      </div> 
                    </div>
                  </div>

                  <div class="tile">
                    <div class="tile-inner blog-type-grid">
                      <img src="@/img/news/11-12-23-blog/anthracite.jpg" alt="">
                      <div class="tile-text blog-type-grid">
                        <p class="article-info-body-text blog-type-grid">
                          Anthracite Grey on White
                        </p>
                      </div> 
                    </div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </section>
        </div>

        <div class="lymer-services-container compdoor">
          <!-- When you buy CompDoor Section -->
          <div class="what-we-do-container lymer-service blog">
            <div class="pt2 what-we-do-grid">
              <div class="services-cont">
                <div class="service-inverted what-we-do side lymer-service">
                  <div class="center">
                    <div class="blog-side-by-side small blog-casement-windows">
                      <div class="side lymer-service-text-pad sm-90 d50 l60">
                        <h2>Bay & bow windows</h2>
                        <h4>Create a wonderful sense of space and light for your home</h4>
                        <ul class="what-we-do-body-text lymer-service">
                          <p class="lymer-service-body-text">
                            Bay and bow windows are an elegant and attractive external feature in any property whilst internally creating a sense of space and light.
                            <br/><br/>
                            Bay and bow windows are created using casement windows which are connected using a strong, structural bay pole assembly or corner posts (for square bay windows) and as with all REHAU products are available in a range of finishes and colours.                
                          </p>              
                        </ul>                        
                      </div>
                      <div class="lymer-services-grid baywindow-blog-bg side sm-90 d50 l40">
                      </div>
                    </div>              

                  </div>
                </div>              
              </div>
            </div> 
          </div>          
        </div>

        <WindowsParallax/>


      </div>
    </main>
  </body>
</template>

<script>
import WindowsParallax from "@/components/WindowsParallax.vue";
    export default {
    name: "News",
  components: {
      WindowsParallax,
      }
    };

</script>

<style lang="scss">

</style>
