@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="Privacy"
    itemscope
    itemtype="http://schema.org/WebPage"
    >   
    <main class="main-wrap">
      <div class="content-wrap">
        <section class="hero">
          <img class="" src="@/img/contact/contact-hero.jpg" alt="The front of Lymers showroom based in Leek, Staffordshire.">
        </section>
        <div class="welcome">
          <div class="">
            <div class="welcome-intro-container">
              <h1>
                Lymer Doors Ltd <br/>Privacy Policy
              </h1>
              <h4>Date: 23/02/2023</h4>
              <p style="text-align: left!important;">
                <b>Our contact details</b>
                <br/>
                Name: Richard Lymer<br/>
                Address:Unit 5 Barnfield Road, Leek, Staffordshire, ST13 5QG<br/>
                Phone Number:01538 388727<br/>
                E-mail:sales@lymerdoors.co.uk<br/>
                Date: 23/04/2023<br/><br/>

                <b>The type of personal information we collect</b><br/>
                We currently collect and process the following information:<br/>
                Personal identifiers, contacts and characteristics (for example, name
                and contact details)
                <br/><br/>
                <b>How we get the personal information and why we have it</b><br/>
                Most of the personal information we process is provided to us directly by
                you for one of the following reasons:<br/>
              </p>
              <ul style="text-align: left;">
                <li>
                  To send quotes requested.
                </li>
                <li>
                  To process orders under contract with you.
                </li>
                <li>
                  Inform you of special offers, no more than 12 times per year.
                </li>
              </ul><br/>
              <p style="text-align: left!important;">
                Under the UK General Data Protection Regulation (UK GDPR), the lawful
                bases we rely on for processing this information are:
              </p>
              <ul style="text-align: left;">
                <li>
                  Your consent. You are able to remove your consent at anytime. You can do this by contacting Lymer Doors Ltd 01538388727
                </li>
                <li>
                  We have a legitimate interest.
                </li>
              </ul>
              <br/>
              <p style="text-align: left!important;">
                <b>How we store your personal information</b><br/>
                Your information is securely stored.<br/>
                We keep your name, address, telephone number and email address for at
                least the length of your guarantee. We will then dispose your information
                by permanently deleting from our data base and shredding all paperwork.

                <b>Your data protection rights</b><br/>
                Under data protection law, you have rights including:<br/>
              </p>
              <br/>
              <ul style="text-align: left!important;">
                <li>
                  Your right of access - You have the right to ask us for copies of your personal information.
                </li>
                <li>
                  Your right to rectification - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
                </li>
                <li>
                  Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.
                </li>
                <li>
                  Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal information in certain circumstances.
                </li>
                <li>
                  Your right to object to processing - You have the the right to object to the processing of your personal information in certain circumstances.
                </li>
                <li>
                  Your right to data portability - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.
                </li>
              </ul>
              <br/>
              <p style="text-align: left!important;">
                You are not required to pay any charge for exercising your rights. If you
                make a request, we have one month to respond to you.
                Please contact us at sales@lymerdoors.co.uk if you wish to make a request.
                <br/>

                <b>How to complain</b><br/>
                If you have any concerns about our use of your personal information, you
                can make a complaint to us at:<br/>
                Lymers<br/>
                Unit 5 Barnfield Road<br/>
                Leek<br/>
                Staffordshire<br/>
                ST13 5QG<br/>
                Phone Number: 01538 388727<br/>
                E-mail: sales@lymerdoors.co.uk<br/>
                <br/>
                You can also complain to the ICO if you are unhappy with how we have used your data.<br/><br/>
                The ICO’s address:<br/>
                Information Commissioner’s Office<br/>
                Wycliffe House<br/>
                Water Lane<br/>
                Wilmslow<br/>
                Cheshire<br/>
                SK9 5AF<br/><br/>
                Helpline number: 0303 123 1113<br/><br/>
                ICO website: https://www.ico.org.uk<br/>
              </p>
            </div>               
          </div>    
        </div>

        

        
        
      </div>
    </main>
  </body>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';

.privacy-container {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6vw;    
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.privacy-policy {
  margin-top: 10rem;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 25px;
  color: black;
  background-image: linear-gradient(263deg, #fc622a, #f8eb70);
  padding: 2em;
}

.privacy-policy-title {
  margin-top: 2vw;
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  text-align: center;    
}

.privacy-policy-sub-title {
  color: #ffffff;
  text-shadow: 2px 2px 6px black;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  margin-top: 2vw;

  @include breakpoint-mobile {
    font-size: 5vw;
    width: auto;
    margin-top: 5vw;
  }
}

.privacy-policy-body-text {
  padding: 2vw;
  margin-left: auto;
  margin-right: auto;
  width: 70vw;
  word-break: break-word;

  @include breakpoint-mobile {
    width: auto;
  }

  a {
    color: #162431 !important;
    cursor: pointer;
    font-weight: bold;
    text-shadow: 2px 2px 3px #ffffff;
  }
}

  
</style>